import React, { FC } from "react";

import { MedioWooCartItem } from "@types";
import { CartItem } from "@components/atoms";

import styles from "./cartItemTable.module.scss";
interface CartItemTableProps {
  items: MedioWooCartItem[];
}

const CartItemTable: FC<CartItemTableProps> = ({ items }) => {
  if (items.length < 1) {
    return null;
  }

  return (
    <div className={styles["cart-items"]}>
      <h3 className={styles["title"]}>Vörur</h3>
      <div className={styles["header"]}>
        <div className={styles["image"]}>Vörur</div>
        <div className={styles["name"]}>Heiti</div>
        <div className={styles["fullprice"]}>Verð</div>
        <div className={styles["quantity"]}>Magn</div>
        <div className={styles["price"]}>Samtals</div>
        <div className={styles["action"]}></div>
      </div>
      <div className={styles["cart-items"]}>
        {items.map((item: MedioWooCartItem) => (
          <CartItem item={item} key={item.key} />
        ))}
      </div>
    </div>
  );
};

export { CartItemTable };
