import { Skeleton } from "antd";
import React from "react";

import gridStyles from "@components/molecules/productGrid/productGrid.module.scss";

import styles from "./productSkeletons.module.scss";

const ProductSkeletons = () => {
  return (
    <div className={gridStyles["otc-grid"]}>
      {Array(Math.floor(Math.random() * 6) + 6)
        .fill("")
        .map((_, index) => (
          <div
            className={`medio-content-box rounded ${styles["otc-skeleton"]}`}
            key={index}
          >
            <Skeleton
              className={styles["top"]}
              paragraph={{ rows: 2 }}
              active
            />
            <Skeleton
              className={styles["center"]}
              title={false}
              paragraph={{ rows: 1 }}
              active
            />
            <Skeleton
              className={styles["bottom"]}
              title={false}
              paragraph={{ rows: 1 }}
              active
            />
            <div className={styles["buttons"]}>
              <Skeleton.Button className={styles["cart"]} active />
            </div>
          </div>
        ))}
    </div>
  );
};

export { ProductSkeletons };
