import { Button, Skeleton } from "antd";
import axios from "axios";
import { useRouter } from "next/router";
import React, { FC } from "react";
import { useQueryClient } from "react-query";

import { toastMessage } from "@kaktus-kreatives/ecommerce";
import {
  clearPrescriptionCartData,
  usePrescriptionCartData
} from "@query-api/cart";
import { MedioPrescriptionCart, MedioWooCart } from "@types";
import { formatPrice, sentryExceptionHandler } from "@utils";

import styles from "./cartTotals.module.scss";

interface CartTotalsProps {
  cart: MedioWooCart;
  prescriptionCart: MedioPrescriptionCart;
}

const CartTotals: FC<CartTotalsProps> = ({ cart, prescriptionCart }) => {
  const router = useRouter();

  const { isFetching } = usePrescriptionCartData();

  const queryClient = useQueryClient();

  const handleClearCart = async () => {
    if (prescriptionCart.total_quantity > 0) {
      clearPrescriptionCartData(queryClient);
      try {
        await axios.delete("/api/cart/fees");
      } catch (e) {
        sentryExceptionHandler(e);
        toastMessage("error", "Ekki tókst að tæma körfu");
      }
    }

    // localStorage.setItem("cart", JSON.stringify([]));
    localStorage.setItem("cart", JSON.stringify([]));
    await queryClient.invalidateQueries(["wooCart"]);

    toastMessage("success", "Karfan þín hefur verið tæmd");
  };
  return (
    <div className={styles["cart-totals"]}>
      <div className={styles["tax"]}>
        <div className={styles["label"]}>Vsk</div>
        <div className={styles["value"]}>
          {isFetching ? (
            <Skeleton paragraph={{ rows: 1 }} title={false} active />
          ) : (
            formatPrice(cart.total_tax)
          )}
        </div>
      </div>
      <div className={styles["total"]}>
        <div className={styles["label"]}>Samtals</div>
        <div className={styles["value"]}>
          {isFetching ? (
            <Skeleton paragraph={{ rows: 1 }} title={false} active />
          ) : (
            formatPrice(cart.total)
          )}
        </div>
      </div>
      <div className={`${styles["buttons"]} right`}>
        <Button
          size="large"
          className="primary"
          onClick={handleClearCart}
          disabled={isFetching}
        >
          Tæma körfu
        </Button>
        <Button
          size="large"
          className="primary-gradient"
          onClick={() => router.push("/greidsluferli")}
          disabled={isFetching}
        >
          Greiða
        </Button>
      </div>
    </div>
  );
};

export { CartTotals };
