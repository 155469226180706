import Link from "next/link";
import React, { FC, useState } from "react";

import Cart from "@assets/svg/cart-small.svg";
import Check from "@assets/svg/check.svg";
import { useUpdateCart } from "@kaktus-kreatives/ecommerce";
import { MedioWooLineItem, MedioWooOrder } from "@types";
import { formatPrice } from "@utils";

import styles from "./lineItems.module.scss";

type LineItemsProps = {
  order: MedioWooOrder;
};

type LineItemProps = {
  item: MedioWooLineItem;
};

const LineItem: FC<LineItemProps> = ({ item }) => {
  const updateCart = useUpdateCart();
  const [inCart, setInCart] = useState(false);
  if (item.name === "Lyfseðilsskylt lyf" || item.product_id === 0) {
    return null;
  }
  return (
    <div className={styles["line-item"]}>
      {!inCart ? (
        <Cart
          className={styles["line-item-cart"]}
          onClick={() => {
            setInCart(true);
            updateCart(item.product_id, item.quantity);
          }}
        />
      ) : (
        <Check className={styles["line-item-check"]} />
      )}

      <Link href={`/vorur/${item.product_id!}`}>
        <a className={styles["line-item-link"]}>
          <div className={styles["line-item-link-name"]}>
            {item.name}
            {item.quantity > 1 && <span> (x{item.quantity})</span>}
          </div>
          <div className={styles["line-item-link-price"]}>
            {formatPrice(item.total)}
          </div>
        </a>
      </Link>
    </div>
  );
};

const LineItems: FC<LineItemsProps> = ({ order }) => {
  if (!order) {
    return null;
  }

  return (
    <div className={styles["line-items"]}>
      {order.line_items.map((item) => (
        <LineItem item={item} key={item.id} />
      ))}
    </div>
  );
};

export { LineItems };
