import React from "react";
import { Skeleton } from "antd";

import styles from "./userBillingSkeleton.module.scss";
const UserBillingSkeleton = () => {
  return (
    <div className="medio-content-box">
      <div className={styles["group"]}>
        <Skeleton active paragraph={{ rows: 1 }} />
        <Skeleton active paragraph={{ rows: 1 }} />
      </div>
      <div className={styles["group"]}>
        <Skeleton active paragraph={{ rows: 1 }} />
        <Skeleton active paragraph={{ rows: 1 }} />
      </div>
      <div className={styles["single"]}>
        <Skeleton active paragraph={{ rows: 1 }} />
      </div>
      <div className={styles["group"]}>
        <Skeleton active paragraph={{ rows: 1 }} />
        <Skeleton active paragraph={{ rows: 1 }} />
      </div>
      <div className={styles["single"]}>
        <Skeleton active paragraph={{ rows: 1 }} />
      </div>
      <div className={styles["single"]}>
        <Skeleton active paragraph={{ rows: 1 }} />
      </div>
      {/* <Skeleton active  /> */}
    </div>
  );
};

export { UserBillingSkeleton };
