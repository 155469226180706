import { Skeleton } from "antd";
import { Tabs } from "antd";
import React from "react";
import { FC } from "react";

const { TabPane } = Tabs;

import styles from "./productDetailsSkeleton.module.scss";

const ProductDetailsSkeleton: FC = () => {
  return (
    <div className={styles["grid"]}>
      <div className={styles["product__image"]}>
        <Skeleton.Image className={styles["image-skeleton"]} />
      </div>
      <div className={styles["product__info"]}>
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
        <div className={styles["product__info__long"]}>
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        </div>
        <div className={styles["product__info__tags"]}>
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        </div>
        <div className={styles["product__info__links"]}>
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        </div>
        <div className={styles["product__info__description"]}>
          <Skeleton active title={false} paragraph={{ rows: 4 }} />
        </div>
        <div className={styles["product__info__price__container"]}>
          {/* TODO: Possibly create new component just for the details page? */}
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
          <Skeleton
            className={styles["product__info__price__container__price"]}
            active
            title={false}
            paragraph={{ rows: 1 }}
          />
        </div>
      </div>
      <div className={styles["product__tabs"]}>
        <Tabs>
          {/* TODO: Find something other than description to put here. */}
          <TabPane tab="Vörulýsing" key="1">
            <Skeleton active title={false} paragraph={{ rows: 4 }} />
          </TabPane>
          <TabPane tab="Notkun" key="2">
            <Skeleton active title={false} paragraph={{ rows: 3 }} />
          </TabPane>
          <TabPane tab="Frekari upplýsingar" key="3">
            <div className={styles["product__tabs__item__subitem"]}>
              <span className={styles["label"]}>Skráning:</span>
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </div>
            <div className={styles["product__tabs__item__subitem"]}>
              <span className={styles["label"]}>Markaðsleyfishafi:</span>
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </div>
            <div className={styles["product__tabs__item__subitem"]}>
              <span className={styles["label"]}>Umbodsadili:</span>
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </div>
            <div className={styles["product__tabs__item__subitem"]}>
              <span className={styles["label"]}>Heiti innihaldsefnis:</span>
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </div>
            <div className={styles["product__tabs__item__subitem"]}>
              <span className={styles["label"]}>Innihald:</span>
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export { ProductDetailsSkeleton };
