import { Skeleton } from "antd";
import React from "react";

import skeleStyles from "./checkoutOptionSkeleton.module.scss";

const CheckoutOptionSkeleton = () => {
  return (
    <>
      {Array(3)
        .fill("")
        .map((_, index) => (
          <Skeleton
            key={index}
            className={`medio-content-box rounded ${skeleStyles["checkout-skeleton"]}`}
            active
            title={false}
            paragraph={{ rows: 2 }}
          />
        ))}
      <div className={skeleStyles["button-container"]}>
        <Skeleton.Button shape="round" size="large" active />
        <Skeleton.Button shape="round" size="large" active />
      </div>
    </>
  );
};

export { CheckoutOptionSkeleton };
