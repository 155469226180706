// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const isDev = process.env.NODE_ENV !== "production";

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://241689211fb7474f97667c0d4419b23d@o1119016.ingest.sentry.io/6153282",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  integrations: isDev
    ? []
    : [
        new CaptureConsoleIntegration({
          // array of methods that should be captured
          // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
          levels: ["error"],
        }),
      ],
  beforeSend: (event) => {
    if (isDev) {
      return null;
    }
    return event;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
