import { Collapse } from "antd";
import axios from "axios";
import classNames from "classnames";
import { useRouter } from "next/router";
import Script from "next/script";
import React, { FC, useEffect, useState } from "react";
import { FiCreditCard, FiDollarSign } from "react-icons/fi";
import { useQueryClient } from "react-query";

import { CheckoutOptionSkeleton } from "@components/atoms";
import {
  toastMessage,
  usePaymentMethodsData
} from "@kaktus-kreatives/ecommerce";
import { sentryExceptionHandler, uuid } from "@utils";
import { initRapyd, reinitRapyd } from "@utils/initRapyd";

import {
  CheckoutFooter,
  CreditCardInput,
  PeiInput,
  UserUpdateFormSkeleton
} from "../../molecules";
import styles from "./checkoutPaymentMethods.module.scss";

const { Panel } = Collapse;
interface CheckoutPaymentMethodsProps {
  handleStepChange: any;
  checkoutButtonText: string;
  checkoutPrevious: any;
  onClick: any;
  selected: any;
}

const CheckoutPaymentMethods: FC<CheckoutPaymentMethodsProps> = ({
  handleStepChange,
  checkoutButtonText,
  checkoutPrevious,
  onClick,
  selected,
}) => {
  // TODO: Cleanup rendering and split components

  const router = useRouter();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(true);
  const [paymentPending, setPaymentPending] = useState(false);
  const [isDisabled, setIsDisabled] = useState(
    selected === "cod" ? false : true
  );

  const { data: paymentMethods, isSuccess } = usePaymentMethodsData();
  const rapydCheckoutId = router.query.checkoutId;
  // This prevents undefined paymentMethods
  const onChangeHandler = (nextKey) => {
    if (nextKey) {
      onClick(nextKey);
    }
  };

  // const handleCheckout = async () => {
  //   setPaymentPending(true);
  //   const idExternal = uuid();
  //   let prescriptions;
  //   try {
  //     prescriptions = JSON.parse(
  //       localStorage.getItem("prescriptionCart")
  //     ).line_items;

  //     if (prescriptions.length > 0) {
  //       const { data } = await axios.post("/api/rxa/orders/", {
  //         prescriptions,
  //         idExternal,
  //       });

  //       await checkoutNext({
  //         _idExternal: idExternal, // Add RXA idExternal to wooOrder
  //         _rxa_order_id: data, // RXA order ID
  //       });
  //       localStorage.removeItem("prescriptionCart");
  //     } else {
  //       await checkoutNext();
  //     }
  //     setPaymentPending(false);
  //   } catch (e) {
  //     // data mismatch
  //     if (e.response.status === 409) {
  //       toastMessage("error", "Villa í körfu, karfan hefur verið tæmd.", {
  //         id: "order-error",
  //       });
  //       localStorage.removeItem("cart");
  //       localStorage.removeItem("prescriptionCart");
  //       queryClient.invalidateQueries();
  //       router.push("/");
  //     } else {
  //       if (e.response?.data?.message) {
  //         toastMessage(
  //           "error",
  //           "Ekki tókst að fá heimild á kort, vinsamlegast athugið kortaupplýsingar.",
  //           {
  //             id: "order-error",
  //           }
  //         );
  //       } else {
  //         toastMessage("error", "Villa hefur komið upp við pöntun", {
  //           id: "order-error",
  //         });
  //         sentryExceptionHandler(e);
  //       }
  //     }

  //     try {
  //       // prescriptions === prescriptionCart.line_items
  //       if (prescriptions.length > 0) {
  //         await axios.post(`/api/rxa/orders/cancel/${idExternal}`);
  //         toastMessage("error", "Hætti við RXA pöntun", {
  //           id: "rxa-order-error",
  //         });
  //       }
  //     } catch (e) {
  //       sentryExceptionHandler(e);
  //     }
  //   }
  //   setPaymentPending(false);
  // };

  useEffect(() => {
    if (!rapydCheckoutId) {
      toastMessage("error", "Villa í pöntun, vinsamlegast reyndu aftur", {
        id: "rapyd-order-error",
      });
      return handleStepChange(1);
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setLoading(false);
      if (!selected) {
        onClick(paymentMethods[0]);
      }
    }
  }, [isSuccess]);

  // If selected payment method is Cash on delivery button is not diabled
  useEffect(() => {
    if (selected === "cod") {
      setIsDisabled(false);
    }
  }, [selected]);

  useEffect(() => {
    reinitRapyd(rapydCheckoutId);
  }, [paymentMethods, loading, window]);

  if (!isSuccess) {
    return <CheckoutOptionSkeleton />;
  }

  return (
    <>
      <Collapse
        accordion
        activeKey={selected}
        ghost
        expandIconPosition={"right"}
        className={styles["checkout-payment-methods"]}
        onChange={onChangeHandler}
      >
        {paymentMethods.includes("cod") && (
          <Panel
            header="Greiða við afhendingu"
            key="cod"
            className={classNames(
              "medio-content-box rounded has-icon",
              styles["no-options"]
            )}
            extra={
              <FiDollarSign
                className="collapsible-icon"
                size={24}
                stroke="#35b9bf"
              />
            }
          />
        )}
        {paymentMethods.includes("rapyd") && (
          <>
            <Script
              id="rapyd-script"
              src="https://sandboxcheckouttoolkit.rapyd.net"
              onLoad={() => initRapyd(rapydCheckoutId)}
            />
            <Panel
              header="Greiðslukort"
              key="rapyd"
              className={classNames("medio-content-box rounded has-icon", {
                [styles["no-margin"]]: selected === "rapyd",
              })}
              extra={
                <FiCreditCard
                  className="collapsible-icon"
                  size={24}
                  stroke="#35b9bf"
                />
              }
            >
              <div id="rapyd-checkout">
                <div
                  id="rapyd-checkout-skeleton"
                  style={{ paddingTop: "3.2rem" }}
                >
                  <UserUpdateFormSkeleton isPaymentForm />
                </div>
              </div>
            </Panel>
          </>
        )}
      </Collapse>
      <CheckoutFooter
        previousAction={checkoutPrevious}
        buttonText={checkoutButtonText}
        isLoading={paymentPending}
        isDisabled={isDisabled}
      />
    </>
  );
};

export { CheckoutPaymentMethods };
