import React, { FC } from "react";
import classNames from "classnames";

import { MedioWooOrder } from "@types";
import { OrderDetailsNotes } from "@components/atoms";
import {
  OrderDetailsAddress,
  OrderDetailsGeneral,
  OrderDetailsSummary,
} from "@components/molecules";

import styles from "./orderDetailsContent.module.scss";

type OrderDetailsProps = {
  order: MedioWooOrder;
};

const OrderDetailsContent: FC<OrderDetailsProps> = ({ order }) => (
  <div className={classNames(styles["order-details-content"])}>
    <OrderDetailsGeneral order={order} />
    <OrderDetailsSummary order={order} />

    <OrderDetailsAddress order={order} />

    <OrderDetailsNotes order={order} />
  </div>
);

export { OrderDetailsContent };
