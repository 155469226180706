import classNames from "classnames";
import React, { FC } from "react";

import { OrderDetailsNotes } from "@components/atoms";
import {
  OrderDetailsGeneral,
  OrderDetailsPending,
  OrderDetailsSummary
} from "@components/molecules";
import { MedioWooOrder } from "@types";

import styles from "./thankYouContent.module.scss";

type OrderDetailsProps = {
  order: MedioWooOrder;
  pending?: boolean;
};

const ThankYouContent: FC<OrderDetailsProps> = ({ order, pending = false }) => (
  <div className={classNames(styles["thank-you-content"])}>
    {pending ? (
      <OrderDetailsPending />
    ) : (
      <>
        <OrderDetailsGeneral order={order} />
        <OrderDetailsSummary order={order} />
        <OrderDetailsNotes order={order} />
      </>
    )}
  </div>
);

export { ThankYouContent };
