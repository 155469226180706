import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import Icon from "@ant-design/icons";
import classnames from "classnames";

import styles from "./pageHeader.module.scss";
import MedioSVG from "@assets/svg/medio_logo.svg";
import { getHours } from "date-fns";
import { useUserData } from "@query-api";

const { Header } = Layout;

const MedioLogo = (props: any) => <Icon component={MedioSVG} {...props} />;

const PageHeader = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const { data } = useUserData();

  const handleScroll = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY < 1) {
        return setIsScrolled(false);
      }

      if (window.scrollY > 0) {
        if (!isScrolled) {
          return setIsScrolled(true);
        }
      }
    }
  };

  const getGreeting = () => {
    if (typeof window !== "undefined") {
      if (window.innerWidth <= 480) {
        return "Hæ";
      }
    }
    const hours = getHours(new Date());
    if (hours >= 6 && hours <= 17) {
      return "Góðan daginn ";
    }
    return "Góða kvöldið ";
  };

  const getName = () => {
    if (!data || !data.billing.first_name) {
      return null;
    }
    return data.billing.first_name.split(" ")[0] || data.billing.first_name;
  };

  const renderGreeting = () => {
    if (!data) {
      return null;
    }
    return (
      <div className={styles["user"]}>
        <span className={styles["greeting"]}>{getGreeting()}</span>
        <span className={styles["name"]}>{getName()}</span>
      </div>
    );
  };
  useEffect(() => {
    window.addEventListener("scroll", (event) => handleScroll());
    return window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Header
      className={classnames(styles["page-header"], {
        [styles["scrolled"]]: isScrolled,
      })}
    >
      <div className={styles["content"]}>
        <a href="/">
          <MedioLogo className={styles["site-logo"]} />
        </a>
        {renderGreeting()}
      </div>
    </Header>
  );
};

export { PageHeader };
