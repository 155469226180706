import React, { FC } from "react";
import Link from "next/link";

import { MedioWooOrder } from "@types";
import { formatDateShort, formatPrice, WooStatus } from "@utils";
import { OrdersTableHeader, StatusTag } from "@components/atoms";

import styles from "./ordersTable.module.scss";

interface OrdersTableProps {
  orders: MedioWooOrder[];
}

const OrdersTable: FC<OrdersTableProps> = ({ orders }) => {
  return (
    <div className={styles["orders"]}>
      <OrdersTableHeader />
      <div className={styles["list"]}>
        {orders.map((order: MedioWooOrder) => (
          <div className={styles["order"]} key={order.id}>
            <div className={styles["id"]}>
              <span className={styles["label"]}>Pöntun</span>
              <div className={styles["title"]}>
                <Link href={`/pantanir/${order.id}`}>
                  <a className={styles["id"]}>
                    <div className={styles["id"]}>#{order.id}</div>
                  </a>
                </Link>
              </div>
            </div>
            <div className={styles["date"]}>
              <span className={styles["label"]}>Dagsetning</span>
              {formatDateShort(order.date_created.date)}
            </div>
            <div className={styles["shipping-method"]}>
              <span className={styles["label"]}>Afhendingarmáti</span>
              {order.shipping_method}
            </div>
            <div className={styles["total"]}>
              <span className={styles["label"]}>Samtals</span>
              {formatPrice(order.total)}
            </div>
            <div className={styles["status"]}>
              <span className={styles["label"]}>Staða</span>
              <div className={styles["status"]}></div>
              <StatusTag status={order.status as WooStatus} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { OrdersTable };
