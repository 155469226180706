import { Collapse } from "antd";
import React, { FC } from "react";
import { FiShoppingCart } from "react-icons/fi";

import { CheckoutItem, PrescriptionCheckoutItem } from "@components/atoms";
import { CheckOutSummarySkeleton } from "@components/molecules";
import { MedioPrescriptionCart, MedioWooCart, MedioWooCartItem } from "@types";
import { formatPrice } from "@utils";

import styles from "./checkoutSummary.module.scss";

const { Panel } = Collapse;

interface CheckoutSummaryProps {
  cartData: MedioWooCart;
  prescriptionCartData: MedioPrescriptionCart;
  shipping: any;
}

const CheckoutSummary: FC<CheckoutSummaryProps> = ({
  cartData,
  shipping,
  prescriptionCartData,
}) => {
  if (
    (!cartData || cartData.line_items.length < 1) &&
    (!prescriptionCartData || prescriptionCartData.line_items.length < 1)
  ) {
    return <CheckOutSummarySkeleton />;
  }

  const getSummaryContent = () => (
    <div className={styles["checkout-summary"]}>
      <div className={styles["items"]}>
        {prescriptionCartData?.line_items.map((item, index: number) => (
          <PrescriptionCheckoutItem item={item} key={index} />
        ))}
        {cartData?.line_items.map((item: MedioWooCartItem) => (
          <CheckoutItem item={item} key={item.key} />
        ))}
      </div>
      <div className={styles["spacer"]} />
      <div className={styles["item"]}>
        <div className={styles["title"]}>Samtals</div>
        <div className={styles["value"]}>{formatPrice(cartData.total)}</div>
      </div>
      <div className={styles["item"]}>
        <div className={styles["title"]}>Sendingarkostnaður</div>
        <div className={styles["value"]}>
          {formatPrice(shipping?.cost || 0)}
        </div>
      </div>
      <div className={`${styles["item"]} ${styles["tax"]}`}>
        <div className={styles["title"]}>Þar af Vsk</div>
        <div className={styles["value"]}>{formatPrice(cartData.total_tax)}</div>
      </div>
      <div className={styles["spacer"]} />
      <div className={`${styles["item"]} ${styles["final"]}`}>
        <div className={styles["title"]}>Samtals</div>
        <div className={styles["value"]}>
          {formatPrice(+cartData.total + +parseInt(shipping?.cost || 0))}
        </div>
      </div>
    </div>
  );

  if (typeof window !== "undefined") {
    if (window.innerWidth >= 1200) {
      return getSummaryContent();
    }
  }

  return (
    <Collapse ghost expandIconPosition={"right"} className="checkout-summary">
      <Panel
        header={`Samtals: ${
          cartData.total === 0
            ? formatPrice(
                +prescriptionCartData.total + +parseInt(shipping?.cost || 0)
              )
            : formatPrice(+cartData.total + +parseInt(shipping?.cost || 0))
        }`}
        key="summary"
        className="has-icon"
        extra={
          <FiShoppingCart
            className="collapsible-icon"
            size={24}
            stroke="#35b9bf"
          />
        }
      >
        {getSummaryContent()}
      </Panel>
    </Collapse>
  );
};

export { CheckoutSummary };
