import { Button, Form, Input } from "antd";
import React, { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { WooBilling } from "@kaktus-kreatives/ecommerce";
import { updateUserBilling } from "@query-api";

import styles from "./userBilling.module.scss";

const { Item } = Form;
const { Group } = Input;

const UserBilling: FC<{
  billing: WooBilling;
  setFormValues?: any;
}> = ({ billing, setFormValues }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { mutate: mutateUserBilling } = useMutation(
    (billing: WooBilling) => updateUserBilling(billing),
    {
      onMutate: () => setLoading(true),
      onSuccess: () => queryClient.invalidateQueries("wooUser"),
      onSettled: () => setLoading(false),
    }
  );

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={mutateUserBilling}
      scrollToFirstError
      initialValues={billing}
      onValuesChange={(_, data) => (setFormValues ? setFormValues(data) : null)}
    >
      <Group className={styles["user-billing"]}>
        <Item
          label="Netfang"
          required
          name="email"
          className={styles["email"]}
          rules={[
            {
              required: true,
              type: "email",
            },
          ]}
        >
          <Input placeholder="Netfang" />
        </Item>
        <Item
          label="Sími"
          required
          name="phone"
          className={styles["phone"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Sími" />
        </Item>
        <Item
          label="Nafn"
          required
          name="first_name"
          className={styles["first-name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Nafn" />
        </Item>
        <Item
          label="Eftirnafn"
          required
          name="last_name"
          className={styles["last-name"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Eftirnafn" />
        </Item>
        <Item
          label="Heimilisfang"
          required
          name="address_1"
          className={styles["address-1"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Heimilisfang" />
        </Item>
        <Item
          label="Staður"
          required
          name="city"
          className={styles["city"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Staður" />
        </Item>
        <Item
          label="Póstnúmer"
          required
          name="postcode"
          className={styles["postcode"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Póstnúmer" />
        </Item>
        <Item label="Fyrirtæki" name="company" className={styles["company"]}>
          <Input placeholder="Fyrirtæki" />
        </Item>
        {!setFormValues && (
          <Item className={styles["save"]}>
            <Button
              type="primary"
              className="primary"
              size="large"
              htmlType="submit"
            >
              Vista
            </Button>
          </Item>
        )}
      </Group>
    </Form>
  );
};

export { UserBilling };
