import { Spin } from "antd";
import React, { FC } from "react";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./loadingSpinner.module.scss";

const spinner = <LoadingOutlined style={{ fontSize: 48 }} spin />;

interface LoadingSpinnerProps {
  loading: boolean;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({ loading }) => {
  if (!loading) {
    return null;
  }

  return (
    <div className={styles["loading-spinner"]}>
      <Spin indicator={spinner} />
    </div>
  );
};

export { LoadingSpinner };
