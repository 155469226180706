import React, { FC } from "react";
import { Button, Checkbox, Form, Input, Spin, Switch } from "antd";
import Cleave from "cleave.js/react";
const { Item } = Form;
const { Group } = Input;
import styles from "./peiInput.module.scss";

interface PeiRegisterFormProps {
  onFinish: (data: any) => void;
}

const PeiRegisterForm: FC<PeiRegisterFormProps> = ({ onFinish }) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      scrollToFirstError
      className={styles["pei-register"]}
    >
      <Group className={styles["pei-input"]}>
        <Item
          label="Kennitala"
          required
          name="ssn"
          className={styles["ssn"]}
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              len: 10,
              message: "Kennitala verður að vera 10 tölustafir",
              transform: (value: string) => value.replaceAll(/\s/g, ""),
            },
          ]}
        >
          <Cleave
            autoComplete="ssn"
            className="ant-input"
            options={{
              blocks: [6, 4],
              numericOnly: true,
            }}
            placeholder="Kennitala"
          />
        </Item>
        <Item
          label="Símanúmer"
          required
          name="phone"
          className={styles["phone"]}
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              len: 7,
              message: "Símanúmer verður að vera 7 tölustafir",
              transform: (value: string) =>
                value ? value.replaceAll(/\s/g, "") : value,
            },
          ]}
        >
          <Cleave
            autoComplete="tel"
            className="ant-input"
            options={{
              blocks: [3, 4],
              numericOnly: true,
            }}
            placeholder="Símanúmer"
          />
        </Item>
        <Item
          label="Vista greiðslumáta"
          required
          name="shouldSave"
          className={styles["shouldSave"]}
          initialValue={true}
          valuePropName="checked"
        >
          <Checkbox />
        </Item>
        <Item className={styles["input-button"]}>
          <Button
            type="primary"
            className="primary"
            size="large"
            htmlType="submit"
          >
            Fá staðfestingarkóða
          </Button>
        </Item>
      </Group>
    </Form>
  );
};

export { PeiRegisterForm };
