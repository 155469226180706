import { Button, Input } from "antd";
import classnames from "classnames";
import React, { useState } from "react";
import { connectPagination } from "react-instantsearch-dom";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import styles from "./algoliaPagination.module.scss";

const CustomAlgoliaPagination = (props) => {
  const { canRefine, currentRefinement, refine, nbPages } = props;

  const [page, setPage] = useState(currentRefinement);

  const handlePageChange = (nextPage: any) => {
    if (nextPage > nbPages) {
      nextPage = nbPages;
    }

    if (nextPage < 1) {
      nextPage = 1;
    }
    setPage(nextPage);
    refine(nextPage);
    if (window) {
      setTimeout(() => window.scrollTo({ behavior: "smooth", top: 0 }), 500);
    }
  };

  const handleKeyDown = (e) => {
    // If enter key pressed
    if (e.keyCode === 13) {
      handlePageChange(page);
    }
  };

  return (
    canRefine && (
      <div className={styles["container"]}>
        {currentRefinement > 1 ? (
          <Button onClick={() => handlePageChange(currentRefinement - 1)}>
            <LeftOutlined />
          </Button>
        ) : (
          <Button disabled>
            <LeftOutlined />
          </Button>
        )}
        <div className={styles["pages"]}>
          <p>Síða </p>
          <Input
            value={page}
            onKeyDown={(e) => handleKeyDown(e)}
            onChange={(e) => setPage(e.target.value)}
          />
          <p> af {nbPages}</p>
        </div>
        {currentRefinement < nbPages ? (
          <Button onClick={() => handlePageChange(currentRefinement + 1)}>
            <RightOutlined />
          </Button>
        ) : (
          <Button disabled>
            <RightOutlined />
          </Button>
        )}
      </div>
    )
  );
};

const AlgoliaPagination = connectPagination(CustomAlgoliaPagination);

export { AlgoliaPagination };
