import React, { FC } from "react";

import styles from "./pageTitle.module.scss";

interface PageTitleProps {
  title: string;
  subtitle?: string;
}

const PageTitle: FC<PageTitleProps> = ({ title, subtitle }) => {
  return (
    <div className={styles["page-title"]}>
      <div className={styles["title"]}>{title}</div>
      {subtitle && <div className={styles["subtitle"]}>{subtitle}</div>}
    </div>
  );
};

export { PageTitle };
