import React, { FC } from "react";
import { Skeleton } from "antd";

import { datePlusYear, formatDateShort, formatPrice } from "@utils";
import { MedioStatus } from "@types";

import styles from "./siStatusText.module.scss";

interface SiStatusTextProps {
  data?: MedioStatus;
}

const SiStatusText: FC<SiStatusTextProps> = ({ data }) => {
  if (!data) {
    return (
      <div className={styles["si-status-text"]}>
        Lyfjaárið þitt hófst <Skeleton active paragraph={false} /> og endar{" "}
        <Skeleton active paragraph={false} />.<br />
        Þú hefur greitt <Skeleton active paragraph={false} /> á tímabilinu og
        ert því í þrepi <Skeleton active paragraph={false} />.
      </div>
    );
  }

  return (
    <div>
      Lyfjaárið þitt hófst{" "}
      <strong>{formatDateShort(data.timabilupphafdags)}</strong> og endar{" "}
      <strong>{datePlusYear(data.timabilupphafdags)}</strong>.<br />
      Þú hefur greitt <strong>{formatPrice(data.timabilgreitt)} </strong>á
      tímabilinu og ert því í þrepi <strong>{data.threp}</strong>.
    </div>
  );
};

export { SiStatusText };
