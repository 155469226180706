import { Tag } from "antd";
import React, { FC } from "react";

import { WooStatus } from "@utils";

import styles from "./statusTag.module.scss";

interface StatusTagProps {
  status: WooStatus;
}

const statusTypes: { [key: string]: { label: string; color: string } } = {
  pending: {
    label: "Bíður greiðslu",
    color: "cyan",
  },
  processing: {
    label: "Bíður afgreiðslu",
    color: "volcano",
  },
  "in-progress": {
    label: "Í afgreiðslu",
    color: "volcano",
  },
  "on-hold": {
    label: "Í bið",
    color: "cyan",
  },
  cancelled: {
    label: "Hætt við",
    color: "",
  },
  refunded: {
    label: "Endurgreidd",
    color: "",
  },
  failed: {
    label: "Mistókst",
    color: "red",
  },
  completed: {
    label: "Afgreitt",
    color: "green",
  },
  shipping: {
    label: "Afgreitt í pósti",
    color: "purple",
  },
  skutlan: {
    label: "Afgreitt í skutlu",
    color: "purple",
  },
};

const StatusTag: FC<StatusTagProps> = ({ status }) => (
  <Tag className={styles["status-tag"]} color={statusTypes[status].color}>
    {statusTypes[status].label}
  </Tag>
);

export { StatusTag };
