import classNames from "classnames";
import React from "react";

import { ProductSkeletons } from "@components/atoms";
import { Product } from "@components/molecules";
import { useCart } from "@kaktus-kreatives/ecommerce";
import { MedioAlgoliaWooProduct, MedioWooOtcProduct } from "@types";
import { getCartQuantity } from "@utils";

import styles from "./productGrid.module.scss";

interface OtcGridProps {
  products?: MedioWooOtcProduct[];
  hits?: MedioAlgoliaWooProduct[];
}

const ProductGrid = (props: OtcGridProps) => {
  const { hits, products } = props;

  const { data: cart } = useCart();

  let productsToRender = hits ? hits : products;

  return productsToRender.length > 0 ? (
    <div className={classNames(styles["otc-grid"])}>
      {productsToRender.map(
        (
          product: MedioWooOtcProduct | MedioAlgoliaWooProduct,
          index: number
        ) => (
          <div key={index}>
            <Product
              key={product.id}
              product={product}
              cartQuantity={getCartQuantity(product, cart)}
            />
          </div>
        )
      )}
    </div>
  ) : (
    <ProductSkeletons />
  );
};

export { ProductGrid };
