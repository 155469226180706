import { Button } from "antd";
import React from "react";

import styles from "./checkoutFooter.module.scss";

const CheckoutFooter = ({
  previousAction,
  nextAction,
  buttonText,
  isLoading = false,
  isDisabled,
}: any) => {
  return (
    <div className={styles["action"]}>
      <Button className="light" onClick={previousAction}>
        Til baka
      </Button>
      {nextAction && (
        <Button
          className="primary-gradient"
          onClick={nextAction}
          loading={isLoading}
          disabled={isDisabled}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export { CheckoutFooter };
