import axios from "axios";
import { useQuery } from "react-query";

import { handleMedioApiError } from "@utils";

const getProductDetailsData = async (id: string): Promise<any> => {
  try {
    const { data } = await axios(`/api/products/${id}`);
    try {
      const { data: documents } = await axios(
        `/api/drugs/documents?id=${data.sku}`
      );
      return { ...data, ...documents };
    } catch (e) {}
    return data;
  } catch (e) {
    handleMedioApiError(e);
    throw e.response.data;
  }
};

// TODO: Type out product object

const useProductDetailsData = (id: string) => {
  return useQuery<any, Error>({
    queryKey: ["wooProductDetails", id],
    queryFn: () => getProductDetailsData(id),
    retry: false,
  });
};

export { useProductDetailsData, getProductDetailsData };
