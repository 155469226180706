import { Button, Input } from "antd";
import React, { ChangeEvent, FC, useState } from "react";
import { QueryClient } from "react-query";

import {
  PrescriptionActions,
  PrescriptionCartButton,
  PrescriptionGenericsButton
} from "@components/atoms";
import {
  AddPrescriptionToCartProps,
  MedioCustomer,
  MedioPrescription
} from "@types";

import { PrescriptionLabels } from "../prescriptionLabels/prescriptionLabels";
import styles from "./prescriptionCard.module.scss";

type PrescriptionCardProps = {
  prescription: MedioPrescription;
  customer: MedioCustomer;
  genericsAction: (prescription: MedioPrescription) => void;
  cartAction: (props: AddPrescriptionToCartProps) => void;
  isInCart: boolean;
  queryClient: QueryClient;
};

const PrescriptionCard: FC<PrescriptionCardProps> = ({
  prescription,
  customer,
  cartAction,
  genericsAction,
  isInCart,
  queryClient,
}) => {
  if (!prescription) {
    return null;
  }

  const { newPrescriptionType, drug } = prescription;
  const {
    recommendedNumberOfPackages,
    maxNumberOfPackages,
    addictive,
    addictiveMaxPacks,
  } = drug;

  // Checking if this is an addictive drug
  const maxPackages = addictive ? addictiveMaxPacks : maxNumberOfPackages;

  const [numberOfPackages, setNumberOfPackages] = useState(
    recommendedNumberOfPackages
  );

  const setPackages = (value: number) => {
    setNumberOfPackages(value);
    drug.numberOfPackages = value;
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value?.length) {
      setPackages(recommendedNumberOfPackages);
      return;
    }
    let value = parseInt(e.target.value);
    if (isNaN(value)) {
      setPackages(recommendedNumberOfPackages);
      return;
    }

    value = Math.round(value);

    if (value > 0) {
      if (value > maxPackages) {
        setPackages(maxPackages);
      } else {
        setPackages(value);
      }
    } else {
      setPackages(1);
    }
  };

  return (
    <div className={styles["prescription"]}>
      <div className={styles["top-row"]}>
        <p>
          Lyfseðill: <span>{prescription.prescriptionNumber}</span>
        </p>
        {prescription.repeatDispension && (
          <p>
            Hámarksmagn:{" "}
            <span>
              {numberOfPackages}/{maxPackages}
            </span>
          </p>
        )}
      </div>
      <div className={styles["drug-name"]}>
        {prescription.drug.name} {parseFloat(prescription.drug.strength)}{" "}
        {prescription.drug.strengthUnit} -{" "}
        {parseFloat(prescription.drug.quantity)}{" "}
        {prescription.drug.quantityUnit}
      </div>
      <div className={styles["drug-type"]}>
        {prescription.drug.ATCName}
        <div className={styles["packages"]}>
          Fjöldi pakkninga:
          {newPrescriptionType ? (
            <Input
              value={numberOfPackages}
              className={styles["input"]}
              onChange={handleChangeInput}
              disabled={!prescription.canBeOrdered || isInCart}
            />
          ) : (
            <span>{prescription.drug.numberOfPackages}</span>
          )}
        </div>
      </div>
      <PrescriptionLabels prescription={prescription} />
      <PrescriptionActions
        prescription={prescription}
        extra={
          <>
            <div className="left">
              <Button className="light" size="large">
                Skoða nánar
              </Button>
            </div>
            <div className="right">
              <PrescriptionGenericsButton
                prescription={prescription}
                openGenericsModal={(e) => {
                  e.stopPropagation();
                  genericsAction(prescription);
                }}
              />
              <PrescriptionCartButton
                prescription={prescription}
                addPrescriptionToCart={(e) => {
                  e.stopPropagation();
                  cartAction({
                    prescription,
                    kennitala: customer.kennitala,
                    queryClient,
                  });
                }}
                isInCart={isInCart}
              />
            </div>
          </>
        }
      />
      {/* {renderAssetButton(prescription)} */}
    </div>
  );
};

export { PrescriptionCard };
