import classNames from "classnames";
import React, { FC } from "react";
import { MedioDrug } from "@types";
import { formatPrice } from "@utils";

import styles from "./genericDrug.module.scss";

type GenericDrugProps = {
  drug: MedioDrug;
  selected?: boolean;
  onClick: () => void;
};

const GenericDrug: FC<GenericDrugProps> = ({
  drug,
  selected = false,
  onClick,
}) => {
  if (!drug) {
    return null;
  }

  return (
    <button
      onClick={onClick}
      className={classNames(styles["generic-drug"], {
        [styles["selected-drug"]]: selected,
      })}
    >
      <div className={styles["title"]}>
        {drug.name}, {`${+drug.strength} ${drug.strengthUnit}`},{" "}
        {+drug.quantity} {drug.quantityUnit}
      </div>
      <div className={styles["price"]}>
        <div className={styles["label"]}>Viðmiðunarverð:</div>
        <div className={styles["amount"]}>
          {formatPrice(drug.paymentParticipationPrice)}
        </div>
      </div>
    </button>
  );
};

export { GenericDrug };
