import "antd/dist/antd.css";
import "../styles/globals.scss";

import { ConfigProvider, Layout } from "antd";
import classNames from "classnames";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useRef } from "react";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Hydrate } from "react-query/hydration";

import { PageHeader } from "@components/molecules";
import { MobileNavigation, Navigation } from "@components/organisms";
import { validateMessages } from "@utils";

const { Content } = Layout;

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const meta = {
    author: "Kaktus",
    title: "Medio",
    description: "Rafrænt útibú fyrir apótekið þitt",
    image: "https://base.medio.is/wp-content/uploads/2021/03/Medio-OG.png",
    url: "https://kaktus.is",
  };

  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 1000 * 30 * 1,
          cacheTime: 1000 * 60 * 60 * 2,
        },
      },
    });
  }

  const ContentWrapper = ({ children }) => {
    const { pathname } = useRouter();

    return (
      <Content
        className={classNames("content", {
          isCheckout: pathname === "/checkout",
        })}
      >
        {children}
      </Content>
    );
  };

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Hydrate state={pageProps.dehydratedState}>
        <div id="medio-root">
          <ConfigProvider
            form={{
              validateMessages,
              requiredMark: "optional",
            }}
          >
            <Layout className="Medio">
              <Head>
                <meta name="author" content={meta.author}></meta>
                <meta name="title" content={meta.title}></meta>
                <meta name="description" content={meta.description}></meta>
                <meta name="og:title" content={meta.title}></meta>
                <meta name="og:image" content={meta.image}></meta>
                <meta name="og:description" content={meta.description}></meta>
                <meta name="og:url" content={meta.url}></meta>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                />
              </Head>
              {router.asPath.includes("/innskraning") ||
              router.asPath.includes("/audkenning") ? null : (
                <PageHeader />
              )}
              <ContentWrapper>
                {router.asPath.includes("/innskraning") ||
                router.asPath.includes("/audkenning") ||
                router.asPath.includes("/greidsluferli") ? null : (
                  <>
                    <MobileNavigation />
                    <Navigation />
                  </>
                )}
                <Content className="content-main">
                  <Component {...pageProps} />
                </Content>
              </ContentWrapper>
            </Layout>
          </ConfigProvider>
          <Toaster />
        </div>
      </Hydrate>
    </QueryClientProvider>
  );
}

export default MyApp;
