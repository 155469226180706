import { Button, Form, Input } from "antd";
import React, { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { WooShipping } from "@kaktus-kreatives/ecommerce";
import { updateUserShipping } from "@query-api";

import styles from "./userShipping.module.scss";

const { Item } = Form;
const { Group } = Input;

const UserShipping: FC<{
  shipping: WooShipping;
  setFormValues?: any;
}> = ({ shipping, setFormValues }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { mutate: mutateUserShipping } = useMutation(
    (shipping: WooShipping) => updateUserShipping(shipping),
    {
      onMutate: () => setLoading(true),
      onSuccess: () => queryClient.invalidateQueries("wooUser"),
      onSettled: () => setLoading(false),
    }
  );

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={mutateUserShipping}
      scrollToFirstError
      initialValues={shipping}
      onValuesChange={(_, data) => (setFormValues ? setFormValues(data) : null)}
    >
      <Group className={styles["user-shipping"]}>
        <Item
          label="Heimilisfang"
          required
          name="address_1"
          className={styles["address-1"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Heimilisfang" />
        </Item>
        <Item
          label="Staður"
          required
          name="city"
          className={styles["city"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Staður" />
        </Item>
        <Item
          label="Póstnúmer"
          required
          name="postcode"
          className={styles["postcode"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Póstnúmer" />
        </Item>
        <Item label="Fyrirtæki" name="company" className={styles["company"]}>
          <Input placeholder="Fyrirtæki" />
        </Item>
        {!setFormValues && (
          <Item className={styles["save"]}>
            <Button
              type="primary"
              className="primary"
              size="large"
              htmlType="submit"
            >
              Vista
            </Button>
          </Item>
        )}
      </Group>
    </Form>
  );
};

export { UserShipping };
