import { Form, Input } from "antd";
import Cleave from "cleave.js/react";
import React, { FC, useEffect, useState } from "react";
import { FaCcAmex, FaCcMastercard, FaCcVisa } from "react-icons/fa";
import { FiCreditCard } from "react-icons/fi";

import styles from "./creditCardInput.module.scss";

const { Item } = Form;
const { Group } = Input;

interface CreditCardInputProps {
  creditCardInfo: {
    card_number: string;
    expiry_date: string;
    cvc_number: string;
    card_name: string;
  };
  setFormValues: any;
  setIsDisabled: any;
  isSelected: boolean;
}

const CreditCardInput: FC<CreditCardInputProps> = ({
  creditCardInfo,
  setFormValues,
  setIsDisabled,
  isSelected,
}) => {
  const [form] = Form.useForm();
  const [cardType, setCardType] = useState("unknown");

  const cardIcons = {
    unknown: (
      <FiCreditCard
        className={styles["image"]}
        // icon={cardIcons[cardType] || CreditCard1}
        size={24}
        stroke="#35b9bf"
      />
    ),
    visa: <FaCcVisa className={styles["image"]} size={24} stroke="#35b9bf" />,
    mastercard: (
      <FaCcMastercard className={styles["image"]} size={24} stroke="#35b9bf" />
    ),
    amex: <FaCcAmex className={styles["image"]} size={24} stroke="#35b9bf" />,
  };

  const getCardIcon = () => {
    return cardIcons[cardType];
  };

  // Checking validation to set or unset button as disabled
  const handleFormChange = () => {
    const someValueIsEmpty = Object.values(form.getFieldsValue()).some(
      (value: string) => value.length === 0
    );
    setIsDisabled(someValueIsEmpty);
  };

  useEffect(() => {
    // If this payment method gets selected, checking if current values are valid
    if (isSelected) {
      handleFormChange();
    }
  }, [isSelected]);

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={(_, data) => {
        setFormValues({ ...data, card_name: cardType });
      }}
      initialValues={creditCardInfo}
      onFieldsChange={handleFormChange}
      scrollToFirstError
    >
      <Group className={styles["credit-card-input"]}>
        <div className={styles["number-container"]}>
          <Item
            label="Kortanúmer"
            required
            name="card_number"
            className={styles["number"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Cleave
              name="card_number"
              autoComplete="card_number"
              placeholder="Kortanúmer"
              onChange={(e) => form.setFields(["card_number", e.target.value])}
              options={{
                creditCard: true,
                creditCardStrictMode: true,
                onCreditCardTypeChanged: (cardType: string) =>
                  setCardType(cardType),
              }}
              className="ant-input"
            />
          </Item>
          {getCardIcon()}
        </div>
        <Item
          label="Gildistími"
          required
          name="expiry_date"
          className={styles["expiration"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Cleave
            name="expiry_date"
            placeholder="mm/áá"
            options={{
              date: true,
              datePattern: ["m", "y"],
              delimiter: " / ",
            }}
            autoComplete="expiry_date"
            className="ant-input"
          />
        </Item>
        <Item
          label="CVC"
          required
          name="cvc_number"
          className={styles["cvc"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Cleave
            name="cvc_number"
            autoComplete="cvc_number"
            placeholder="cvc"
            options={{
              numericOnly: true,
            }}
            maxLength={3}
            className="ant-input"
          />
        </Item>
      </Group>
    </Form>
  );
};

export { CreditCardInput };
