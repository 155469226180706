import axios from "axios";

declare global {
  interface Window {
    checkout: any;
  }
}

const initRapyd = (rapydCheckoutId) => {
  //@ts-ignore
  let checkout = new RapydCheckoutToolkit({
    id: rapydCheckoutId,
    close_on_complete: false,
    page_type: "collection",
    pay_button_text: "Klára pöntun",
    style: {
      global: {
        fonts: [
          {
            family: "Apercu Pro",
            src: "/fonts/ApercuPro.woff2",
            fontWeight: 300,
          },
          {
            family: "Apercu Pro",
            src: "/fonts/ApercuPro.woff2",
            fontWeight: 400,
          },
          {
            family: "Apercu Pro",
            src: "/fonts/ApercuPro.woff2",
            fontWeight: 500,
          },
          {
            family: "Apercu Pro",
            src: "/fonts/ApercuPro.woff2",
            fontWeight: 600,
          },
        ],
      },
      submit: {
        title: {
          fontFamily: "'Apercu Pro', sans-serif",
        },
        base: {
          fontFamily: "'Apercu Pro', sans-serif",
          background: "linear-gradient(71.21deg, #3d9caa 0%, #779af7 100%)",
          backgroundColor: "#35b9bf",
          borderRadius: "24px",
          padding: ".8rem 0",
          fontSize: "1.6rem !important",
          fontWeight: 500,
          width: "min-content",
          boxShadow: "0 10px 30px rgba(49, 151, 196, 0.1)",
          transition: "box-shadow 0.5s ease, transform 0.3s ease",
        },
        hover: {
          boxShadow: "0 8px 30px rgb(49 151 196 / 60%) !important",
          transform: "translate3d(0, -2px, 0)",
        },
      },
      input: {
        base: {
          fontFamily: "'Apercu Pro', sans-serif",
          width: "100%",
          padding: "1rem 2.4rem",
          background: "#ffffff",
          border: "1px solid #dbdbdb",
          borderRadius: "6px",
          color: "#585858",
          fontSize: "1.6rem",
        },
      },
      dropdown: {
        title: {
          fontFamily: "'Apercu Pro', sans-serif",
        },
        base: {
          fontFamily: "'Apercu Pro', sans-serif",
          width: "100%",
          padding: "1rem 2.4rem",
          background: "#ffffff",
          border: "1px solid #dbdbdb",
          borderRadius: "6px",
          color: "#585858",
          fontSize: "1.6rem",
        },
      },
      orderDetails: {
        title: {
          fontFamily: "'Apercu Pro', sans-serif",
        },
      },
      cardFields: {
        title: {
          fontFamily: "'Apercu Pro', sans-serif",
        },
        base: {
          fontFamily: "'Apercu Pro', sans-serif",
          width: "100%",
          padding: "1rem 2.4rem",
          background: "#ffffff",
          border: "1px solid #dbdbdb",
          borderRadius: "6px",
          color: "#585858",
          fontSize: "1.6rem",
        },
      },
    },
  });

  window.checkout = checkout;

  window.addEventListener("onCheckoutPaymentSuccess", async (event: any) => {
    localStorage.removeItem("cart");
    localStorage.removeItem("prescriptionCart");
    const orderId = event.detail.metadata.orderData.id;
    try {
      await axios.put(`/api/orders/${orderId}`, {
        additional_meta: {
          rapyd_checkout_id: rapydCheckoutId,
          _payment_click: 1,
        },
      });
      location.href = event.detail.redirect_url;
    } catch (e) {
      // TODO sentry
      console.error(e);
    }
  });

  window.addEventListener("onCheckoutFailure", async (event: any) => {
    console.log("onCheckoutFailure:", event);
    try {
      // checkout failure - for example go back from 3ds, then we try to retrieve redirect url again and redirect the user
      const { data } = await axios(`/api/payments/checkout/${rapydCheckoutId}`);
      const { redirect_url } = data;
      if (redirect_url) {
        location.href = redirect_url;
      }
      // no redirect url for some reason
      else {
        // TODO: toast message?
        localStorage.removeItem("cart");
        localStorage.removeItem("prescriptionCart");
        location.href = "/";
      }
    } catch (e) {
      // TODO sentry
      console.error(e);
    }
  });

  const rapydSkeleton = document.getElementById("rapyd-checkout-skeleton");
  if (rapydSkeleton) {
    checkout.displayCheckout();
    rapydSkeleton.remove();
    getLangButtons();
  }
};

const attachUserAndCheckoutIdToOrder = () => {
  axios.post("/api/orders/");
};

const getLangButtons = () => {
  const flags = document.createElement("div");
  flags.className = "rapyd-flags";
  const rapydCheckout = document.getElementById("rapyd-checkout");

  const btnIs = document.createElement("button");
  btnIs.className = "flag is";
  btnIs.onclick = function () {
    window.checkout.changeLanguage("is");
  };
  flags.appendChild(btnIs);

  const btnEn = document.createElement("button");
  btnEn.className = "flag en";
  btnEn.onclick = function () {
    window.checkout.changeLanguage("en");
  };
  flags.appendChild(btnEn);

  const noticeElement = document.createElement("div");
  noticeElement.className = "rapyd-notice";
  noticeElement.innerHTML =
    "Ath: Þar sem unnið er með viðkvæm gögn rennur þessi greiðslusíða út eftir 10 mínútur.";

  rapydCheckout.appendChild(noticeElement);
  rapydCheckout.appendChild(flags);
};

const reinitRapyd = (rapydCheckoutId) => {
  const rapydCheckout = document.getElementById("rapyd-checkout");
  const rapydCheckoutSkeleton = document.getElementById(
    "rapyd-checkout-skeleton"
  );
  if (rapydCheckout && rapydCheckoutSkeleton) {
    if (window && window.checkout) {
      initRapyd(rapydCheckoutId);
    }
  }
};

export { initRapyd, reinitRapyd };
