import { withIronSessionApiRoute, withIronSessionSsr } from "iron-session/next";

import { removeRxaCookies } from "./cookies";

const sessionOptions = {
  password: process.env.IRON_PASS,
  cookieName: "ironCookie",
  // iron-session settar secure sjálfkrafa útfrá http/https
  // cookieOptions: {
  //   secure: process.env.NODE_ENV === "production",
  // },
};

const withSessionSsr = (handler) => {
  return withIronSessionSsr(handler, sessionOptions);
};

const withSessionApiRoute = (handler) => {
  return withIronSessionApiRoute(handler, sessionOptions);
};

const destroySession = async (req, res) => {
  await req.session.destroy();
  removeRxaCookies(res);
};

export { withSessionSsr, withSessionApiRoute, sessionOptions, destroySession };
