// recursive function to get all slugs by post type
const getPostSlugsByType: (
  apiUrl: string | undefined,
  postType: string,
  additionalParameters?: string,
  page?: number,
  slugs?: string[]
) => Promise<string[]> = async (
  apiUrl,
  postType,
  additionalParameters = "",
  page = 1,
  slugs = []
) => {
  const postTypeFinal =
    postType === "post" ? "posts" : postType === "page" ? "pages" : postType;
  // a post type might use some custom logic for it's slug - in that case the post type has to expose
  // a 'slug_full' rest field which is then used
  const path = `/wp-json/wp/v2/${postTypeFinal}?get_post_slugs=1&_fields=slug,slug_full,meta&per_page=100&page=${page}${
    additionalParameters.length ? `&${additionalParameters}` : ""
  }`;
  const url = `${apiUrl}${path}`;
  const res = await fetch(url);
  const data = await res.json();
  // stopping condition is when page number doesn't exist
  if ("data" in data && "status" in data.data && data.data.status === 400) {
    return slugs;
  }
  // filter out excludes and then get slugs
  const dataSlugs = data
    .filter((x: any) => !x?.meta?.exclude_on_frontend)
    .map((x: any) => (x.slug_full?.length ? x.slug_full : x.slug));
  if (dataSlugs.length) {
    return getPostSlugsByType(
      apiUrl,
      postTypeFinal,
      additionalParameters,
      page + 1,
      slugs.concat(dataSlugs)
    );
  }

  return [];
};

export { getPostSlugsByType };
