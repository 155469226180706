import React, { FC } from "react";
import { Button } from "antd";

import { MedioPrescription } from "@types";
import { isDateAfter } from "@utils";

type PrescriptionCartButtonProps = {
  prescription: MedioPrescription;
  addPrescriptionToCart: (e: React.MouseEvent) => void;
  isInCart: boolean;
};

const PrescriptionCartButton: FC<PrescriptionCartButtonProps> = ({
  prescription,
  addPrescriptionToCart,
  isInCart,
}) => {
  if (!prescription) {
    return null;
  }

  if (isInCart) {
    return (
      <Button
        className="prescription-cart-button disabled primary-gradient"
        size="large"
        disabled
      >
        Er í körfu
      </Button>
    );
  }

  if (isDateAfter(prescription.earliestDispense)) {
    return (
      <Button
        className="prescription-cart-button disabled primary-gradient"
        size="large"
        disabled
      >
        Má ekki afgreiða
      </Button>
    );
  }

  if (!prescription.canBeOrdered || prescription.drug.isOnWaitingList) {
    return (
      <Button
        className="prescription-cart-button disabled primary-gradient"
        size="large"
        disabled
      >
        Lyf ekki til
      </Button>
    );
  }

  // TODO: Handle is date after

  return (
    <Button
      className="prescription-cart-button primary-gradient"
      size="large"
      onClick={addPrescriptionToCart}
    >
      Setja í körfu
    </Button>
  );
};

export { PrescriptionCartButton };
