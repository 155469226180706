import axios from "axios";
import { useQuery } from "react-query";

import { handleMedioApiError } from "@utils";

const getHelpData = async (): Promise<any[]> => {
  try {
    const { data } = await axios("/api/help");
    return data;
  } catch (e) {
    handleMedioApiError(e);
    throw e.response.data;
  }
};

const useHelpData = () => {
  return useQuery<any[], Error>({
    queryKey: ["wooHelp"],
    queryFn: () => getHelpData(),
    retry: false
  });
};

export { useHelpData, getHelpData };
