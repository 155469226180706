import React from "react";
import styles from "./siStatusTableHeader.module.scss";

const SiStatusTableHeader = () => {
  return (
    <div className={styles["si-status-header"]}>
      <div className={styles["step"]}>Þrep</div>
      <div className={styles["cost"]}>Lyfjakostnaður á 12 mánaða tímabili*</div>
      <div className={styles["percentage"]}>Greiðsluhluti einstaklinga</div>
    </div>
  );
};

export { SiStatusTableHeader };
