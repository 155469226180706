import { addDays, format, formatDistanceToNow, isAfter } from "date-fns";
import is from "date-fns/locale/is";

import { MedioAlgoliaWooProduct, MedioWooOtcProduct } from "@types";

import { DATE_FORMAT, DATE_FORMAT_SHORT } from "./constants";

const formatDate = (date: Date | string) =>
  format(new Date(date), DATE_FORMAT, {
    locale: is,
  });

const formatDateShort = (date: Date | string) => {
  // Safari fix
  
  if (typeof date === "string") {
    date = date.split(".")[0].replace(/-/g, "/");

    // Replacing wonky date string
    if (date.includes("T")) {
      date = date.replace(/T/g, " ");
    }
  }

  return format(new Date(date), DATE_FORMAT_SHORT, {
    locale: is,
  });
};

const datePlusYear = (date: string) =>
  formatDateShort(addDays(new Date(date), 364));

const formatPrice = (price: number) =>
  `${new Intl.NumberFormat("de-DE").format(Math.round(price))} kr.`;

const isDateAfter = (dateToCompare: string) => {
  return isAfter(new Date(dateToCompare), new Date());
};

const formatDateToNow = (date: Date | string) =>
  formatDistanceToNow(new Date(date), {
    locale: is,
  });

const formatProductInfo = (styrkur, magn) => {
  if (styrkur && magn) {
    return `${styrkur} - ${magn}`;
  }

  return styrkur || magn;
};

const formatProductInfoLong = (product: any) => {
  return product.form
    ? formatProductInfo(product.styrkur, product.magn) + " - " + product.form
    : formatProductInfo(product.styrkur, product.magn);
};

const getRXADrugDescription = (product: any) => {
  return `${parseInt(product.strength)}${product.strengthUnit} - ${parseInt(
    product.quantity
  )} ${product.quantityUnit}`;
};

const formatRXAResponseToWooResponse = (response: any) => {
  if (Object.keys(response).length === 0) {
    return {
      line_items: [],
      total: 0,
      subtotal: 0,
      total_quantity: 0,
      total_tax: 0,
      notices: [],
    };
  }

  const {
    numberOfPackagesInOrder,
    price: orderPrice,
    customerDrugs,
    childrenDrugs,
  } = response;

  const { price, priceWithoutVat, discount, vatAmount } = orderPrice;

  const { drugPrices: customerDrugPrices, kennitala: customerKennitala } =
    customerDrugs;

  const customerPrescriptions = customerDrugPrices.map((drug: any) => {
    const { prescriptionNumber, idDoctor, selectedDrug, price, siPrice } = drug;

    return {
      prescriptionNumber,
      idDoctor,
      selectedDrug,
      price,
      siPrice,
      kennitala: customerKennitala,
    };
  });

  // let childrenPrescriptions = [];

  const childrenPrescriptions = childrenDrugs.map((child) => {
    const { drugPrices: childrenDrugPrices, kennitala: childrenKennitala } =
      child;

    const children = childrenDrugPrices.map((drug) => {
      const { prescriptionNumber, idDoctor, selectedDrug, price, siPrice } =
        drug;

      return {
        prescriptionNumber,
        idDoctor,
        selectedDrug,
        price,
        siPrice,
        kennitala: childrenKennitala,
      };
    });

    return children;
  });

  const flattenedChildrenArray = childrenPrescriptions.flat();
  return {
    line_items: [...customerPrescriptions, ...flattenedChildrenArray],
    total: price,
    subtotal: priceWithoutVat,
    total_quantity: numberOfPackagesInOrder,
    total_tax: vatAmount,
    notices: [],
  };
};

export {
  formatDate,
  formatDateShort,
  formatPrice,
  isDateAfter,
  datePlusYear,
  formatDateToNow,
  formatProductInfo,
  formatProductInfoLong,
  formatRXAResponseToWooResponse,
  getRXADrugDescription,
};
