import { Badge } from "antd";
import Link from "next/link";
import React from "react";

import { formatPrice } from "@utils";

import styles from "./prescriptionCheckoutItem.module.scss";

interface PrescriptionCheckoutItemType {
  item: any;
}

const PrescriptionCheckoutItem = ({ item }) => {
  const getImageUrl = (product) => {
    if (typeof window !== "undefined") {
      if (window.innerWidth < 768) {
        return product.image;
      }
    }
    return product.imageSmall;
  };

  const { selectedDrug, price } = item;

  return (
    <div className={styles["checkout-item"]}>
      <div className={styles["image"]}>
        <Badge count={selectedDrug.numberOfPackages}>
          <img
            src={
              "https://base.medio.is/wp-content/uploads/2021/01/prescription-icon.png"
            }
            alt={`${selectedDrug.name}`}
          />
        </Badge>
      </div>
      <div className={styles["name"]}>
        <div className={styles["title"]}>
          <p>{selectedDrug.name}</p>
        </div>
        {/* <div className="checkout-item-name-description">
          {item.product.description}
        </div> */}
      </div>
      <div className={styles["price"]}>{formatPrice(price.price)}</div>
    </div>
  );
};

export { PrescriptionCheckoutItem };
