import { Carousel } from "antd";
import React from "react";

import styles from "./imageCarousel.module.scss";

interface ImageCarouselProps {
  imageUrls: any[];
}
const ImageCarousel = ({ imageUrls }: ImageCarouselProps) => {
  return (
    <Carousel
      draggable={true}
      className={styles["image"]}
      style={{ width: 360 }}
      dotPosition="bottom"
    >
      {imageUrls.map(({ url }, i) => (
        <img
          key={`image-${url}-${i}`}
          className={styles["image"]}
          src={url}
          alt="product-image"
          width={"100%"}
        />
      ))}
    </Carousel>
  );
};

export { ImageCarousel };
