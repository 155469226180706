import React from "react";
import styles from "./helpSkeletons.module.scss";
import { Skeleton } from "antd";

const HelpSkeletons = () => {
  return (
    <>
      {Array(Math.floor(Math.random() * 3) + 2)
        .fill("")
        .map((_, index) => (
          <div
            className={`medio-content-box rounded ${styles["help-skeleton"]}`}
            key={index}
          >
            <Skeleton paragraph={{ rows: 1 }} active />
          </div>
        ))}
    </>
  );
};

export { HelpSkeletons };
