import React, { FC } from "react";

import { MedioWooOrder } from "@types";
import { OrderDetailsAddressSection } from "@components/atoms/orderDetailsAddressSection/orderDetailsAddressSection";

import styles from "./orderDetailsAddress.module.scss";

type OrderDetailsAddressProps = {
  order: MedioWooOrder;
};

const OrderDetailsAddress: FC<OrderDetailsAddressProps> = ({ order }) => (
  <div className={`${styles["order-address"]} medio-content-box`}>
    <OrderDetailsAddressSection
      title="Heimilisfang greiðanda"
      address={order.billing}
    />
    <OrderDetailsAddressSection
      title="Heimilisfang móttakanda"
      address={order.shipping}
    />
  </div>
);

export { OrderDetailsAddress };
