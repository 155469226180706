import { useRouter } from "next/router";
import React from "react";

import styles from "./productTag.module.scss";

interface ProductTagProps {
  label: string;
}

const ProductTag = ({ label }: ProductTagProps) => {
  const router = useRouter();
  const handleTagClick = () => {
    router.push(`/vorur?leit=${label}`);
  };
  return (
    <div className={styles["container"]} onClick={handleTagClick}>
      <span>{label}</span>
    </div>
  );
};

export { ProductTag };
