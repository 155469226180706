import React, { FC } from "react";

import { MedioOrderLine, MedioWooOrder } from "@types";
import { formatPrice } from "@utils";

import styles from "../lineItems/lineItems.module.scss";
import presStyles from "./lineItemPrescriptions.module.scss";

type LineItemPrescriptionsProps = {
  order: MedioWooOrder;
};

type LineItemPrescriptionProps = {
  item: MedioOrderLine;
};

const LineItemPrescription: FC<LineItemPrescriptionProps> = ({ item }) => (
  <div
    className={`${styles["line-item"]} ${presStyles["line-item-prescription"]}`}
    key={item.prescriptionNumber}
  >
    <div className={`${styles["name"]} ${presStyles["name"]}`}>
      {item.selectedDrug.name}
      {item.selectedDrug.numberOfPackages > 1 && (
        <span> (x{item.selectedDrug.numberOfPackages})</span>
      )}
    </div>
    <div className={styles["price"]}>{formatPrice(item.price.price)}</div>
  </div>
);

const LineItemPrescriptions: FC<LineItemPrescriptionsProps> = ({ order }) => {
  if (!order || !order.rxaOrder) {
    return null;
  }

  return (
    <div className={presStyles["line-item-prescription"]}>
      {order.rxaOrder.customerDrugs.drugPrices.map((item, index) => (
        <LineItemPrescription item={item} key={index} />
      ))}
    </div>
  );
};

export { LineItemPrescriptions };
