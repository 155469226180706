import React, { FC } from "react";
import { Comment, Tooltip } from "antd";
import { UserOutlined, ShopOutlined } from "@ant-design/icons";

import { MedioWooOrder } from "@types";
import { formatDate, formatDateToNow } from "@utils";

import styles from "./orderDetailsNotes.module.scss";

type OrderDetailsNotesProps = {
  order: MedioWooOrder;
};

const OrderDetailsNotes: FC<OrderDetailsNotesProps> = ({ order }) => {
  if (!order.customer_note && order.order_notes.length < 1) {
    return null;
  }

  return (
    <div className={`${styles["order-notes"]} medio-content-box`}>
      {order.customer_note && (
        <Comment
          author={order.billing.first_name}
          avatar={<UserOutlined />}
          content={order.customer_note}
          datetime={
            <Tooltip title={formatDate(order.date_created.date)}>
              <span>{formatDateToNow(order.date_created.date)} síðan</span>
            </Tooltip>
          }
        />
      )}
      {order.order_notes.map((note) => (
        <Comment
          key={note.id}
          author={note.added_by}
          avatar={<ShopOutlined />}
          content={note.content}
          datetime={
            <Tooltip title={formatDate(note.date_created.date)}>
              <span>{formatDateToNow(note.date_created.date)} síðan</span>
            </Tooltip>
          }
        />
      ))}
    </div>
  );
};

export { OrderDetailsNotes };
