import { WooCart } from "@kaktus-kreatives/ecommerce";
import { MedioPrescriptionCart } from "@types";

interface CartQuantityBadgeProps {
  cart: WooCart;
  prescriptionCart: MedioPrescriptionCart;
  isMobile: boolean;
}

const CartQuantityBadge = ({
  cart,
  prescriptionCart,
  isMobile = false,
}: CartQuantityBadgeProps) => {
  let totalQuantity = 0;

  if (cart && cart.total_quantity > 0) {
    totalQuantity = totalQuantity + cart.total_quantity;
  }

  if (prescriptionCart && prescriptionCart.total_quantity > 0) {
    totalQuantity = totalQuantity + prescriptionCart.total_quantity;
  }

  if (totalQuantity <= 0) {
    return null;
  }

  return (
    <>
      <span
        className={
          isMobile ? "main-mobile-navigation-badge" : "main-navigation-badge"
        }
      >
        {totalQuantity}
      </span>
    </>
  );
};

export { CartQuantityBadge };
