import { Skeleton } from "antd";
import classNames from "classnames";
import { FC } from "react";

import styles from "./userUpdateFormSkeleton.module.scss";

const UserUpdateFormSkeleton: FC<any> = ({ isPaymentForm }) => {
  return (
    <>
      <div
        className={classNames(styles["field-container"], {
          "medio-content-box": !isPaymentForm,
        })}
      >
        <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
        <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
        <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
        <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
        <Skeleton
          className={styles["span-2"]}
          active
          paragraph={{ rows: 1, width: "100%" }}
        />
        <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
        <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
        <Skeleton
          className={styles["span-2"]}
          active
          paragraph={{ rows: 1, width: "100%" }}
        />
      </div>
      {!isPaymentForm && (
        <div className={styles["button-container"]}>
          <Skeleton.Button shape="round" size="large" active />
          <Skeleton.Button shape="round" size="large" active />
        </div>
      )}
    </>
  );
};

export { UserUpdateFormSkeleton };
