import { Skeleton } from "antd";

import styles from "./checkOutSummarySkeleton.module.scss";

const CheckOutSummarySkeleton = () => {
  return (
    <div className={styles["container"]}>
      <div className={styles["group-1"]}>
        <Skeleton.Image className={styles["group-1-image"]} />
        <div className={styles["group-1-paragraphs"]}>
          <Skeleton
            paragraph={{ rows: 1, width: "100%" }}
            active
            title={false}
          />
          <Skeleton
            paragraph={{ rows: 1, width: "100%" }}
            active
            title={false}
          />
        </div>
      </div>
      <div className={styles["spacer"]} />

      <div className={styles["group-2"]}>
        <Skeleton paragraph={{ rows: 1, width: "50%" }} active title={false} />
        <Skeleton paragraph={{ rows: 1, width: "50%" }} active title={false} />
        <Skeleton paragraph={{ rows: 1, width: "70%" }} active title={false} />
        <Skeleton paragraph={{ rows: 1, width: "50%" }} active title={false} />
        <Skeleton paragraph={{ rows: 1, width: "60%" }} active title={false} />
        <Skeleton paragraph={{ rows: 1, width: "40%" }} active title={false} />
      </div>
      <div className={styles["spacer"]} />

      <div className={styles["group-3"]}>
        <Skeleton paragraph={{ rows: 1, width: "50%" }} active title={false} />
        <Skeleton paragraph={{ rows: 1, width: "60%" }} active title={false} />
      </div>
    </div>
  );
};

export { CheckOutSummarySkeleton };
