import axios from "axios";
import { useQuery } from "react-query";

import { MedioWooOrder } from "@types";
import { handleMedioApiError } from "@utils";

const getOrdersData = async (): Promise<MedioWooOrder[]> => {
  try {
    const { data } = await axios("/api/orders");
    return data;
  } catch (e) {
    handleMedioApiError(e);
    throw e.response.data;
  }
};

const useOrdersData = () => {
  return useQuery<MedioWooOrder[], Error>({
    queryKey: ["wooOrders"],
    queryFn: () => getOrdersData(),
    retry: false,
  });
};

export { useOrdersData, getOrdersData };
