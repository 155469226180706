/* eslint-disable no-template-curly-in-string */
const typeTemplate = "${label} er ekki gilt";

const validateMessages = {
  default: "'${label}' er ekki er rétt fyllt út",
  required: "'${label}' er nauðsynlegur reitur",
  enum: "'${label}' þarf að vera eitthvað af eftirfarandi [${enum}]",
  whitespace: "'${label}' má ekki vera tómt",
  date: {
    format: "'${label}' er ekki á réttu sniðmáti",
    parse: "Ekki tókst að skrá dagsetningu",
    invalid: "'${label}' er ekki gild dagsetning",
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
  string: {
    len: "'${label}' verður að vera ${len} stafir",
    min: "'${label}' verður að vera minnst ${min} stafir",
    max: "'${label}' má ekki vera lengra en ${max} stafir",
    range: "'${label}' verður að vera milli ${min} og ${max} stafir",
  },
  number: {
    len: "'${label}' verður að vera ${len} stafir",
    min: "'${label}' verður að vera minnst ${min}",
    max: "'${label}' má ekki vera lengra en ${max}",
    range: "'${label}' verður að vera milli ${min} og ${max}",
  },
  array: {
    len: "'${label}' verður að vera ${len} að lengd",
    min: "'${label}' má ekki vera minna en ${min} að lengd",
    max: "'${label}' má ekki vera meira en ${max} að lengd",
    range: "'${label}' verður að vera milli ${min} og ${max} að lengd",
  },
  pattern: {
    // Only usecase is phone number
    mismatch: "'${label}' má aðeins innihalda +, -, ( ) og tölustafi",
  },
};

export { validateMessages };
