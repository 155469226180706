import React, { FC } from "react";
import styles from "./prescriptionLabel.module.scss";

import { formatDate, PrescriptionLabelType } from "@utils";
import { Tooltip } from "antd";

interface PrescriptionLabelProps {
  labelType: PrescriptionLabelType;
  dateReady?: string;
}

const labelTypes = {
  controlled: {
    label: "Eftirritunarskylt",
    tooltip: "Ekki er heimilt að póstsenda lyfið.",
  },
  rSigned: {
    label: "R merkt",
    tooltip:
      "Læknir hefur R-merkt lyfið og því er ekki hægt að skipta út fyrir samheitalyf",
  },
  waitingList: {
    label: "Lyf á biðlista",
    tooltip:
      "Þetta lyf er ófáanlegt hjá heildsala. Ef samheitalyf er ekki í boði, vinsamlegast hafðu samband.",
  },
  genericDrugs: {
    label: "Samheitalyf í boði",
    tooltip: "Hægt er að að skipta út uppáskrifuðu lyfi fyrir samheitalyf",
  },
  prescriptionNotReady: {
    label: "Má ekki afgreiða strax",
    tooltip: "Lyfseðil má afgreiða í fyrsta lagi ",
  },
};

const PrescriptionLabel: FC<PrescriptionLabelProps> = ({
  labelType,
  dateReady,
}) => (
  <div
    className={`${styles["prescription-labels-label"]} ${styles[labelType]}`}
  >
    <Tooltip
      placement="bottom"
      title={
        dateReady
          ? `${labelTypes[labelType].tooltip} ${formatDate(dateReady)}`
          : labelTypes[labelType].tooltip
      }
      className="prescription-tooltip"
    >
      <div className="label">{`${labelTypes[labelType].label}`}</div>
    </Tooltip>
  </div>
);

export { PrescriptionLabel };
