import React, { FC } from "react";
import classNames from "classnames";

import { MedioStatus } from "@types";
import { SiStatusTableHeader } from "@components/atoms";

import styles from "./siStatusTable.module.scss";

type SiStatusTableProps = {
  data: MedioStatus;
};

const SiStatusTable: FC<SiStatusTableProps> = ({ data }) => {
  const costSteps = {
    ALM: [
      { text: "0 kr. – 22.000 kr.", percentage: "100%" },
      { text: "22.000 kr. – 31.750 kr.", percentage: "15%" },
      { text: "31.750 kr. – 62.000 kr.", percentage: "7.5%" },
      { text: "> 62.000 kr.", percentage: "0%" },
    ],
    other: [
      { text: "0 kr. – 14.500 kr.", percentage: "100%" },
      { text: "14.500 kr. – 20.875 kr.", percentage: "15%" },
      { text: "20.875 kr. – 41.000 kr.", percentage: "7.5%" },
      { text: "> 41.000 kr.", percentage: "0%" },
    ],
  };

  const getCostSteps = (customerStada: string) => {
    return customerStada === "ALM" ? costSteps["ALM"] : costSteps["other"];
  };

  if (!data) {
    return <span>Loading status table...</span>;
  }

  const tableData = getCostSteps(data.stada2).map((item, index) => ({
    index: index + 1,
    ...item,
  }));

  return (
    <>
      <div className={styles["si-status"]}>
        <SiStatusTableHeader />
        <div className={styles["items"]}>
          {tableData.map((item) => (
            <div
              key={item.index}
              className={classNames(styles["item"], {
                [styles["active"]]: data.threp === item.index,
              })}
            >
              <div className={styles["step"]}>
                <span className={styles["label"]}>Þrep</span>
                {item.index}
              </div>
              <div className={styles["cost"]}>
                <span className={styles["label"]}>
                  Lyfjakostnaður á 12 mánaða tímabili*
                </span>
                {item.text}
              </div>
              <div className={styles["percentage"]}>
                <span className={styles["label"]}>
                  Greiðsluhluti einstaklinga
                </span>
                {item.percentage}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export { SiStatusTable };
