import React, { FC } from "react";
import { formatPrice } from "@utils";
import classnames from "classnames";

import styles from "./modalTotals.module.scss";

interface ModalTotalsProps {
  price?: number;
  priceDifference?: number;
}

const ModalTotals: FC<ModalTotalsProps> = ({ price, priceDifference }) => {
  const priceDifferencePrefix = () => {
    if (priceDifference && priceDifference > 0) {
      return "+";
    }
    return "";
  };

  return (
    <div className={styles["modal-totals"]}>
      <div className={styles["tax"]}>
        <div className={styles["label"]}>Verðmunur </div>
        <div
          className={classnames(styles["value"], {
            [styles["positive"]]: priceDifferencePrefix() === "+",
            [styles["negative"]]: priceDifference! < 0,
          })}
        >
          {priceDifferencePrefix()}
          {formatPrice(priceDifference || 0)}
        </div>
      </div>
      <div className={styles["total"]}>
        <div className={styles["label"]}>Viðmiðunarverð</div>
        <div className={styles["value"]}>{formatPrice(price || 0)}</div>
      </div>
    </div>
  );
};

export { ModalTotals };
