import axios from "axios";

import { destroySession, setRxaCookie } from "@utils";

import { getRxaCookieValue } from "./cookies";
import { RequestMethod } from "./enums";
import { sentryExceptionHandler } from "./sentry";

interface HandleApiRequestAdditionalOptions {
  skipAuthCheck?: boolean;
}

const handleApiRequest = async (
  req,
  res,
  path: string,
  method: RequestMethod = RequestMethod.GET,
  headers = null,
  options: HandleApiRequestAdditionalOptions = null
) => {
  try {
    const user = req.session?.user;

    if (!options?.skipAuthCheck && !user && !headers?.Authorization) {
      return res.status(401).send();
    }

    if (
      method === RequestMethod.POST ||
      method === RequestMethod.PATCH ||
      method === RequestMethod.PUT
    ) {
      if (!req.body) {
        return res.status(400).send();
      }
    }

    try {
      const { data } = await axios(
        `${process.env.API_URL}${path}`,
        user
          ? {
              headers: {
                Authorization: `Bearer ${user?.token}`,
                ...headers,
              },
              data: req.body,
              method,
            }
          : null
      );

      return res.status(200).json(data);
    } catch (e) {
      if (
        e.response.status === 400 ||
        e.response.status === 401 ||
        e.response.status === 403
      ) {
        if (e.response.status !== 400) {
          await destroySession(req, res);
        }
        return res
          .status(e.response.status)
          .json({ message: { ...e.response.data } });
      }
      sentryExceptionHandler(e);
      return res.status(500).json(e.response.data);
    }
  } catch (e) {
    sentryExceptionHandler(e);
    return res.status(500).json({ message: e });
  }
};

const withShouldRefreshToken = (handler) => {
  const shouldRefreshToken = async (req, res, ...args) => {
    // RXA && WP tokens
    if (!req.cookies.rxa) return res.status(400).send("RXA cookie missing");

    if (!req.session.user) return res.status(401).send("Unauthorized user");

    const {
      third_party_token,
      third_party_refresh_token,
      third_party_token_expiry,
    } = getRxaCookieValue(req);

    const rxa_token_exp = third_party_token_expiry;
    const rxa_refresh_token = third_party_refresh_token;
    const rxa_token = third_party_token;
    const wp_refresh_token = req.session.user.refresh_token;

    // Refresh tokens if RXA expiry is less than 1 hour
    if (
      // @ts-ignore
      (new Date(rxa_token_exp * 1000) - new Date()) / (1000 * 60 * 60 * 1) <
      0.5
    ) {
      try {
        // Refresh RXA token
        const { data: rxa_data } = await axios.post(
          new URL("/api/rxa/refresh", process.env.NEXT_PUBLIC_URL).href,
          {
            rxa_refresh_token,
            rxa_token,
          }
        );
        const { token, refreshToken, tokenExp, refreshExp } = rxa_data;

        const { data: wp_data } = await axios.post(
          new URL("/api/users/refresh", process.env.NEXT_PUBLIC_URL).href,
          {
            wp_refresh_token,
          }
        );

        const { token: new_wp_token, refresh_token: new_wp_refresh_token } =
          wp_data;

        req.session.user = {
          ...req.session.user,
          token: new_wp_token,
          refresh_token: new_wp_refresh_token,
        };

        const rxaData = {
          third_party_token: token,
          third_party_refresh_token: refreshToken,
          third_party_token_expiry: tokenExp,
          third_party_refresh_token_expiry: refreshExp,
        };

        setRxaCookie(res, rxaData);

        await req.session.save();
      } catch (e) {
        sentryExceptionHandler(e);
        await destroySession(req, res);
      }
    }

    return handler(req, res, ...args);
  };

  return shouldRefreshToken;
};

export { handleApiRequest, withShouldRefreshToken };
