import classNames from "classnames";
import Link from "next/link";
import React, { FC } from "react";

import { RightOutlined } from "@ant-design/icons";
import { CustomerPrescriptionCount } from "@components/atoms";
import { MedioCustomer } from "@types";

import styles from "./prescriptionCustomer.module.scss";

type PrescriptionCustomerProps = {
  customer: MedioCustomer;
};

const PrescriptionCustomer: FC<PrescriptionCustomerProps> = ({ customer }) => {
  if (!customer) {
    return null;
  }

  const getDetailsText = (customer: MedioCustomer) => {
    if (customer.prescriptions.length === 1) {
      return `${customer.prescriptions.length} lyfseðill í gáttinni`;
    }
    return `${customer.prescriptions.length} lyfseðlar í gáttinni`;
  };

  const isDisabled = customer.prescriptions.length <= 0;

  return (
    <div
      className={classNames(styles["prescription-customer"], {
        [styles["prescription-customer-disabled"]]: isDisabled,
      })}
    >
      <CustomerPrescriptionCount customer={customer} />
      <div className={styles["info"]}>
        <div className={styles["name"]}>{customer.name}</div>
        <div className={styles["details"]}>{getDetailsText(customer)}</div>
      </div>
      <Link href={`/lyfsedlar/${customer.kennitala}`}>
        <a href="" className={styles["navigator"]} />
      </Link>
      <div className={styles["link"]}>
        Sjá nánar
        <RightOutlined />
      </div>
    </div>
  );
};

export { PrescriptionCustomer };
