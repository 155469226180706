import axios from "axios";
import { useQuery } from "react-query";

import {
  toastMessage,
  WooBilling,
  WooCheckoutUser,
  WooCustomer,
  WooShipping
} from "@kaktus-kreatives/ecommerce";
import { handleMedioApiError } from "@utils";

const getUserData = async (): Promise<WooCustomer> => {
  try {
    const { data } = await axios("/api/users");
    return data;
  } catch (e) {
    handleMedioApiError(e);
    throw e.response.data;
  }
};

const updateUser = async (user: WooCheckoutUser) => {
  try {
    const { data } = await axios.put("/api/users", user);
    toastMessage("success", "Notendaupplýsingar vistaðar.", {
      id: "user-update",
    });
    return data;
  } catch (e) {
    handleMedioApiError(e);
    throw e.response.data;
  }
};

const updateUserShipping = async (shipping: WooShipping) => {
  try {
    const { data } = await axios.put("/api/users/shipping", shipping);
    toastMessage("success", "Sendingarupplýsingar vistaðar.", {
      id: "user-update",
    });
    return data;
  } catch (e) {
    handleMedioApiError(e);
    throw e.response.data;
  }
};

const updateUserBilling = async (billing: WooBilling) => {
  try {
    const { data } = await axios.put("/api/users/billing", billing);
    toastMessage("success", "Greiðsluupplýsingar vistaðar.", {
      id: "user-update",
    });
    return data;
  } catch (e) {
    handleMedioApiError(e);
    throw e.response.data;
  }
};

const useUserData = () => {
  return useQuery<WooCustomer, Error>({
    queryKey: ["wooUser"],
    queryFn: () => getUserData(),
    retry: false,
  });
};

export {
  useUserData,
  updateUser,
  updateUserShipping,
  updateUserBilling,
  getUserData,
};
