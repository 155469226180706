import {
  WooCart,
  WooCartItem,
  WooCartItemPlaceholder
} from "@kaktus-kreatives/ecommerce";

const getCartQuantity = (product: any, cart: WooCart) => {
  if (!cart || !cart.line_items) {
    return 0;
  }

  const itemsInCart = cart!.line_items.filter((item: WooCartItem) => {
    return item && item.product && item.product.id === product.id;
  });

  if (itemsInCart.length > 0) {
    return itemsInCart[0].quantity;
  } else {
    const placeholderItemInCart = cart!.placeholder_items?.find(
      (item: WooCartItemPlaceholder) => {
        return item && item.id === product.id;
      }
    );
    if (placeholderItemInCart) {
      return placeholderItemInCart.quantity;
    }
  }
  return 0;
};

const getCartKey = (product: any, cart: WooCart) => {
  if (!cart || !cart.line_items) {
    return "";
  }
  const itemsInCart = cart!.line_items.filter((item: WooCartItem) => {
    return item && item.product && item.product.id === product.id;
  });
  if (itemsInCart.length > 0) {
    return itemsInCart[0].key;
  }
  return "";
};

export { getCartQuantity, getCartKey };
