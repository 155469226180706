import React, { FC } from "react";

import { MedioWooOrder } from "@types";
import { formatPrice } from "@utils";
import { LineItems, LineItemPrescriptions } from "@components/atoms";

import styles from "./orderDetailsSummary.module.scss";

type OrderDetailsSummaryProps = {
  order: MedioWooOrder;
};

const OrderDetailsSummary: FC<OrderDetailsSummaryProps> = ({ order }) => {
  if (!order) {
    return null;
  }

  return (
    <div className={`${styles["order-summary"]} medio-content-box`}>
      <div className={styles["items"]}>
        <LineItems order={order} />
        <LineItemPrescriptions order={order} />
      </div>
      <div className={styles["divider"]} />
      <div className={styles["item"]}>
        <div className={styles["name"]}>Sendingarkostnaður:</div>
        <div className={styles["price"]}>
          {formatPrice(order.shipping_total)}
        </div>
      </div>
      <div className={styles["totals"]}>
        <div className={styles["item"]}>
          <div className={styles["name"]}>Vsk:</div>
          <div className={styles["price"]}>{formatPrice(order.total_tax)}</div>
        </div>
        <div className={styles["item"]}>
          <div className={styles["name"]}>Samtals:</div>
          <div className={styles["price"]}>{formatPrice(order.total)}</div>
        </div>
      </div>
    </div>
  );
};

export { OrderDetailsSummary };
