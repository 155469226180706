import { Routes } from "@types";

const routes: Routes = {
  login: {
    path: "/innskraning",
    title: "Innskráning",
    icon: "login",
  },
  prescriptionCustomers: {
    path: "/lyfsedlar",
    title: "Einstaklingar",
    icon: "experiment",
  },
  prescriptions: {
    path: "/lyfsedlar/:kt",
    title: "Lyfseðlar",
    icon: "hidden",
  },
  products: {
    path: "/vorur",
    title: " Vörur",
    icon: "medicine-box",
  },
  orders: {
    path: "/pantanir",
    title: "Pantanir",
    icon: "file-text",
  },
  orderDetails: {
    path: "/pantanir/:id",
    title: "Pöntun",
    icon: "hidden",
  },
  thankYou: {
    path: "/pantanir/takk/:id",
    title: "Takk fyrir",
    icon: "hidden",
  },
  status: {
    path: "/stada",
    title: "Staða",
    icon: "line-chart",
  },
  cart: {
    path: "/karfa",
    title: "Karfa",
    icon: "shopping-cart",
  },
  checkout: {
    path: "/greidsluferli",
    title: "Greiðsluferli",
    icon: "hidden",
  },
  settings: {
    path: "/stillingar",
    title: "Stillingar",
    icon: "setting",
  },
  help: {
    path: "/hjalp",
    title: "Hjálp",
    icon: "question-circle",
  },
};

export { routes };
