import React, { FC } from "react";
import Lottie from "react-lottie-player";

import animationData from "@assets/lottie/errorMessage.json";

import styles from "./errorMessage.module.scss";

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ message = "Villa!" }) => {
  return (
    <div className={styles["medio-error"]}>
      <Lottie loop play animationData={animationData} audioFactory={null} />
      <h3>{message}</h3>
    </div>
  );
};

export { ErrorMessage };
