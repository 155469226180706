import { Form, Input, Spin } from "antd";
import axios from "axios";
import React, { FC, useState } from "react";
import { FiCheckCircle, FiSmartphone } from "react-icons/fi";
import { useQueryClient } from "react-query";

import { toastMessage } from "@kaktus-kreatives/ecommerce";

import styles from "./peiInput.module.scss";
import { PeiRegisterForm } from "./peiRegisterForm";
import { PeiValidateForm } from "./peiValidateForm";

const { Item } = Form;
const { Group } = Input;
const PeiInput: FC = () => {
  const [status, setStatus] = useState("register");
  const [savedFormData, setSavedFormData] = useState({});
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const getPanelLogo = () => (
    <FiSmartphone className="collapsible-icon" size={24} stroke="#35b9bf" />
  );

  const onRegister = async (formData: any) => {
    setStatus("loading");
    setSavedFormData(formData);
    try {
      const { data } = await axios.post("/wp-json/pei/v1/pin", formData);
    } catch (e) {
      toastMessage("error", `Villa: ${e.response.data.message}`);
      return setStatus("register");
    }
    toastMessage("success", `SMS er á leiðinni í síma: ${formData.phone}`);
    setStatus("validate");
  };

  const onValidate = async (formData: any) => {
    setStatus("loading");
    try {
      const { data } = await axios.post("/wp-json/pei/v1/validate", {
        ...formData,
        ...savedFormData,
      });
    } catch (e) {
      console.log(e);
      toastMessage("error", `Villa: ${e.response.data.message}`);
      return setStatus("validate");
    }
    toastMessage("success", `Pei greiðsluleið staðfest`);
    setStatus("valid");
  };

  return (
    <div className={styles["pei-input"]}>
      {status === "register" && <PeiRegisterForm onFinish={onRegister} />}
      {status === "loading" && <Spin />}
      {status === "validate" && <PeiValidateForm onFinish={onValidate} />}
      {status === "valid" && (
        <div>
          <FiCheckCircle size={24} stroke="#35b9bf" />
          <h3>Tilbúið til notkunar</h3>
        </div>
      )}
    </div>
  );
};

export { PeiInput };
