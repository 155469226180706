import axios from "axios";
import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { CheckoutOptionSkeleton } from "@components/atoms";
import {
  toastMessage,
  useShippingMethodsData,
  WooShippingMethod
} from "@kaktus-kreatives/ecommerce";
import { formatPrice } from "@utils";

import { CheckoutFooter } from "../";
import styles from "./checkoutShippingMethods.module.scss";

interface CheckoutShippingMethodsProps {
  onClick: any;
  selected: any;
  checkoutPrevious: any;
  checkoutNext: any;
  checkoutButtonText: string;
  user: any;
}

const CheckoutShippingMethods: FC<CheckoutShippingMethodsProps> = ({
  onClick,
  selected,
  checkoutPrevious,
  checkoutNext,
  checkoutButtonText,
  user,
}) => {
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(true);

  const cart = JSON.parse(localStorage.getItem("cart"));

  const {
    data: shippingMethods,
    isSuccess,
    isLoading,
    isFetching,
  } = useShippingMethodsData({
    cart,
    user,
  });

  const handleCheckoutNext = async () => {
    setLoading(true);

    try {
      await axios.put("/api/users/preferredShipping", {
        preferredShippingMethod: selected,
      });

      await queryClient.invalidateQueries(["wooUser"]);
    } catch (e) {
      toastMessage("error", e.response.data.message);
    }

    try {
      await checkoutNext();
    } catch (e) {
      // TODO sentry?
      console.error(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (isSuccess) {
      if (!selected) {
        onClick(shippingMethods[0]);
      }
      setLoading(false);
    }
  }, [isSuccess]);

  if (!isSuccess) {
    return <CheckoutOptionSkeleton />;
  }

  return (
    <>
      <div className={styles["checkout-shipping-methods"]}>
        {shippingMethods.map((item, index) => (
          <button
            onClick={() => onClick(item)}
            key={index}
            className={classNames(
              "medio-content-box rounded",
              [styles["item"]],
              {
                [styles["selected"]]: selected && selected.id === item.id,
              },
              {
                // If selected shipping method is undefined, select the first method as default
                [styles["selected"]]:
                  typeof selected === "undefined" && index === 0,
              }
            )}
          >
            <div className={styles["title"]}>{item.label}</div>
            {/* <div className={styles["description"]}>{item.description}</div> */}
            <div className={styles["price"]}>
              {formatPrice(parseInt(item.cost))}
            </div>
          </button>
        ))}
      </div>
      <CheckoutFooter
        previousAction={checkoutPrevious}
        nextAction={handleCheckoutNext}
        buttonText={checkoutButtonText}
        isDisabled={false}
        isLoading={loading}
      />
    </>
  );
};

export { CheckoutShippingMethods };
