import { Skeleton } from "antd";
import classNames from "classnames";
import React, { FC } from "react";

import styles from "./cartItemTableSkeleton.module.scss";

const CartItemTableSkeleton: FC = () => {
  return (
    <div className={styles["cart-items"]}>
      <h3 className={styles["title"]}>Vörur</h3>
      <div className={styles["header"]}>
        <div className={styles["image"]}>Vörur</div>
        <div className={styles["name"]}>Heiti</div>
        <div className={styles["fullprice"]}>Verð</div>
        <div className={styles["quantity"]}>Magn</div>
        <div className={styles["price"]}>Samtals</div>
        <div className={styles["action"]}></div>
      </div>

      {Array(Math.floor(Math.random() * 3) + 1)
        .fill("")
        .map((_, index) => (
          <div
            key={index}
            className={classNames("medio-content-box", styles["item-skeleton"])}
          >
            <Skeleton.Image className={styles["image-container"]} />

            <div
              className={classNames(
                styles["skeleton-container"],
                styles["desktop-header"]
              )}
            >
              <Skeleton active paragraph={{ rows: 1, width: "80%" }} />
            </div>
            <div
              className={classNames(
                styles["skeleton-container"],
                styles["phone-header"]
              )}
            >
              <p className={styles["phone-header"]}>Heiti</p>
              <Skeleton active paragraph={{ rows: 0 }} />
            </div>
            <div
              className={classNames(
                styles["skeleton-container"],
                styles["phone-header"]
              )}
            >
              <p className={styles["phone-header"]}>Lýsing</p>
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </div>

            <div className={styles["skeleton-container"]}>
              <p className={styles["phone-header"]}>Verð</p>
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </div>
            <div className={styles["skeleton-container"]}>
              <p className={styles["phone-header"]}>Magn</p>
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </div>
            <div className={styles["skeleton-container"]}>
              <p className={styles["phone-header"]}>Samtals</p>
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </div>
            <div className={styles["skeleton-container"]}>
              <p className={styles["phone-header"]}>Eyða</p>
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </div>
          </div>
        ))}
    </div>
  );
};

export { CartItemTableSkeleton };
