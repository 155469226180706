import React, { FC } from "react";
import Lottie from "react-lottie-player";

import styles from "./medioEmpty.module.scss";

interface MedioEmptyProps {
  animationData: any;
  title: string;
}

const MedioEmpty: FC<MedioEmptyProps> = ({ animationData, title }) => (
  <div className={styles["medio-empty"]}>
    <Lottie
      loop={false}
      play
      animationData={animationData}
      audioFactory={null}
    />
    <h3 className={styles["title"]}>{title}</h3>
  </div>
);

export { MedioEmpty };
