import { Button, Modal } from "antd";
import React, { FC, useEffect, useState } from "react";
import { QueryClient } from "react-query";

import {
  AddPrescriptionToCartProps,
  MedioCustomer,
  MedioDrug,
  MedioPrescription,
  MedioWooCartItem,
  SwapToCartProps
} from "@types";
import { sortByPrice } from "@utils";

import { GenericDrug, ModalTotals } from "../../atoms";
import styles from "./genericsModal.module.scss";

type GenericsModalProps = {
  visible: boolean;
  prescription: MedioPrescription;
  customer: MedioCustomer;
  cartAction: (props: AddPrescriptionToCartProps) => void;
  swapAction: ({ id, nvnr }: SwapToCartProps) => void;
  onCancel: () => void;
  loading: boolean;
  isInCart: boolean;
  cartItem?: MedioWooCartItem | any;
  queryClient: QueryClient;
};

const GenericsModal: FC<GenericsModalProps> = ({
  visible,
  prescription,
  customer,
  cartAction,
  swapAction,
  onCancel,
  loading,
  isInCart,
  cartItem,
  queryClient,
}) => {
  const [selectedDrug, setSelectedDrug] = useState<MedioDrug>();

  useEffect(() => {
    if (prescription) {
      if (prescription.drug.isOnWaitingList) {
        return setSelectedDrug(prescription.genericDrugs[0]);
      }
      return setSelectedDrug(prescription.drug);
    }
  }, [prescription]);

  if (!prescription) {
    return null;
  }

  const isSelected = (drug: MedioDrug) => drug === selectedDrug;

  const handleOk = () => {
    if (isInCart) {
      if (selectedDrug!.nvnr === cartItem!.nvnr) {
        return onCancel();
      }
      swapAction({
        id: prescription.prescriptionNumber,
        nvnr: selectedDrug!.nvnr,
      });
    } else {
      cartAction({
        prescription,
        kennitala: customer.kennitala,
        queryClient,
        generic: selectedDrug!,
      });
    }
    onCancel();
  };

  return (
    <Modal
      title={"Samheitalyf"}
      visible={visible}
      onOk={handleOk}
      confirmLoading={loading}
      onCancel={onCancel}
      okText={"Velja lyf"}
      cancelText={"Hætta við"}
      style={{ maxWidth: "800px" }}
      width="90vw"
      className={styles["generics-modal"]}
      wrapClassName="medio-modal-root"
      centered
      closable={false}
      footer={[
        <div className={styles["footer"]} key="footer-content">
          <Button key="back" size="large" className="light" onClick={onCancel}>
            Hætta við
          </Button>
          <Button
            key="submit"
            type="primary"
            size="large"
            className="primary-gradient"
            loading={loading}
            onClick={handleOk}
          >
            Staðfesta
          </Button>
        </div>,
      ]}
    >
      <div className={styles["title"]}>Uppáskrifað lyf</div>
      <GenericDrug
        drug={prescription.drug}
        onClick={() => setSelectedDrug(prescription.drug)}
        selected={isSelected(prescription.drug)}
      />

      <div className={styles["title"]}>Samheitalyf</div>
      <div className={styles["list"]}>
        {sortByPrice(prescription.genericDrugs).map((drug: MedioDrug) => (
          <GenericDrug
            drug={drug}
            key={drug.id}
            onClick={() => setSelectedDrug(drug)}
            selected={isSelected(drug)}
          />
        ))}
      </div>
      <ModalTotals
        price={selectedDrug?.paymentParticipationPrice}
        priceDifference={
          (selectedDrug?.paymentParticipationPrice || 0) -
          prescription.drug.paymentParticipationPrice
        }
      />
    </Modal>
  );
};

export { GenericsModal };
