import { Skeleton } from "antd";
import React from "react";

import styles from "./orderDetailsSkeletons.module.scss";
import orderDetailsStyles from "../../organisms/orderDetailsContent/orderDetailsContent.module.scss";

const OrderDetailsSkeletons = () => (
  <div
    className={`${orderDetailsStyles["order-details-content"]} ${styles["order-details-content-skeletons"]}`}
  >
    <div className={`${styles["general"]} medio-content-box`}>
      <Skeleton active title={false} paragraph={{ rows: 5 }} />
      <Skeleton active title={false} paragraph={{ rows: 5 }} />
    </div>
    <div className={`${styles["summary"]} medio-content-box`}>
      <Skeleton active title={false} paragraph={{ rows: 5 }} />
      <Skeleton active title={false} paragraph={{ rows: 5 }} />
    </div>
    <div className={`${styles["address"]} medio-content-box`}>
      <Skeleton active title={false} paragraph={{ rows: 7 }} />
      <Skeleton active title={false} paragraph={{ rows: 4 }} />
    </div>
    <div className={`${styles["notes"]} medio-content-box`}>
      <Skeleton active title={false} />
    </div>
  </div>
);

export { OrderDetailsSkeletons };
