import React from "react";
import prescriptionStyles from "../../molecules/prescriptionCard/prescriptionCard.module.scss";
import styles from "./prescriptionSkeletons.module.scss";
import { Skeleton } from "antd";

const PrescriptionSkeletons = () => {
  return (
    <>
      {Array(Math.floor(Math.random() * 5) + 1)
        .fill("")
        .map((_, index) => (
          <div
            className={`${prescriptionStyles["prescription"]} ${styles["prescription-skeleton"]}`}
            key={index}
          >
            <Skeleton
              className={styles["top-row"]}
              title={false}
              active
              paragraph={{ rows: 2 }}
            />
            {
              <Skeleton
                className={styles["title"]}
                active
                paragraph={{ rows: 0 }}
              />
            }
            {
              <Skeleton
                className={styles["center-row"]}
                active
                title={false}
                paragraph={{ rows: 2 }}
              />
            }

            <div className={styles["bottom-row"]}>
              <Skeleton.Button active />
              <Skeleton.Button active />
            </div>
          </div>
        ))}
    </>
  );
};

export { PrescriptionSkeletons };
