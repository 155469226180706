import axios from "axios";

import { MedioOrder, MedioWooOrder } from "@types";
import { handleMedioApiError } from "@utils";

const getOrderData = async (
  id: string,
  headers?: any
): Promise<MedioWooOrder> => {
  try {
    const response = await axios(
      new URL(`/api/orders/${id}`, process.env.NEXT_PUBLIC_URL).href,
      {
        withCredentials: true,
        headers,
      }
    );
    return response.data;
  } catch (e) {
    handleMedioApiError(e);
    throw e.response.data;
  }
};

const getRxaOrderData = async (
  id: string,
  headers?: any
): Promise<MedioOrder> => {
  try {
    const { data } = await axios(
      new URL(`/api/rxa/orders/${id}`, process.env.NEXT_PUBLIC_URL).href,
      {
        withCredentials: true,
        headers,
      }
    );
    return data[0];
  } catch (e) {
    handleMedioApiError(e);
    throw e.response.data;
  }
  // TODO: Error handling must be split between RXA and WOO
};

const getOrderDetailsData = async (
  id: string,
  params?: URLSearchParams,
  headers?: any
): Promise<MedioWooOrder> => {
  try {
    const wooOrder = await getOrderData(id, headers);
    if (wooOrder.rxa_id === "") {
      return wooOrder;
    }

    const rxaOrder = await getRxaOrderData(wooOrder.rxa_id!, headers);
    return { ...wooOrder, rxaOrder };
  } catch (e) {
    handleMedioApiError(e);
    throw e.response.data;
  }
};

export { getOrderDetailsData, getRxaOrderData };
