import React, { FC } from "react";

import { MedioPrescription } from "@types";
import { isDateAfter, PrescriptionLabelType } from "@utils";
import { PrescriptionLabel } from "@components/atoms";

import styles from "./prescriptionLabels.module.scss";

interface PrescriptionLabelsProps {
  prescription: MedioPrescription;
}

const PrescriptionLabels: FC<PrescriptionLabelsProps> = ({ prescription }) => {
  if (
    !isDateAfter(prescription.earliestDispense) &&
    prescription.genericDrugs.length === 0 &&
    !prescription.rSigned &&
    !prescription.drug.isOnWaitingList &&
    !prescription.rSigned &&
    !prescription.controlled
  ) {
    return null;
  }

  return (
    <div className={styles["prescription-labels"]}>
      {isDateAfter(prescription.earliestDispense) && (
        <PrescriptionLabel
          labelType={"prescriptionNotReady" as PrescriptionLabelType}
          dateReady={prescription.earliestDispense}
        />
      )}
      {prescription.genericDrugs.length > 0 && !prescription.rSigned && (
        <PrescriptionLabel
          labelType={"genericDrugs" as PrescriptionLabelType}
        />
      )}
      {prescription.drug.isOnWaitingList && (
        <PrescriptionLabel labelType={"waitingList" as PrescriptionLabelType} />
      )}
      {prescription.controlled && (
        <PrescriptionLabel labelType={"controlled" as PrescriptionLabelType} />
      )}
      {prescription.rSigned && (
        <PrescriptionLabel labelType={"rSigned" as PrescriptionLabelType} />
      )}
    </div>
  );
};

export { PrescriptionLabels };
