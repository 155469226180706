import React, { FC } from "react";
import { MedioCustomer } from "@types";
import PrescriptionIconSVG from "@assets/svg/prescription_icon.svg";

import styles from "./customerPrescriptionCount.module.scss";

type CustomerPrescriptionCounterProps = {
  customer: MedioCustomer;
};

const CustomerPrescriptionCount: FC<CustomerPrescriptionCounterProps> = ({
  customer,
}) => {
  if (!customer) {
    return null;
  }

  return (
    <div className={styles["customer-prescription-count"]}>
      <PrescriptionIconSVG />
      {customer.prescriptions.length > 0 ? (
        <div className={styles["counter"]}>
          <span>{customer.prescriptions.length}</span>
        </div>
      ) : null}
    </div>
  );
};

export { CustomerPrescriptionCount };
