import { Badge } from "antd";
import Link from "next/link";
import React, { FC } from "react";

import { MedioWooCartItem, MedioWooCartItemProduct } from "@types";
import { formatPrice } from "@utils";

import styles from "./checkoutItem.module.scss";

interface CheckoutItemProps {
  item: MedioWooCartItem;
}

const CheckoutItem: FC<CheckoutItemProps> = ({ item }) => {
  const getImageUrl = (product: MedioWooCartItemProduct) => {
    if (typeof window !== "undefined") {
      if (window.innerWidth < 768) {
        return product.image;
      }
    }
    return product.imageSmall;
  };

  return (
    <div className={styles["checkout-item"]}>
      <div className={styles["image"]}>
        <Badge count={item.quantity}>
          <img src={item.product.imageSmall} alt={`${item.product.name}`} />
        </Badge>
      </div>
      <div className={styles["name"]}>
        <div className={styles["title"]}>
          <Link href={`/vorur/${item.product.slug}`}>
            <a>{item.product.name}</a>
          </Link>
        </div>
        {/* <div className="checkout-item-name-description">
          {item.product.description}
        </div> */}
      </div>
      <div className={styles["price"]}>
        {formatPrice(item.line_subtotal + item.line_subtotal_tax)}
      </div>
    </div>
  );
};

export { CheckoutItem };
