import { Input } from "antd";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { connectSearchBox } from "react-instantsearch-dom";

import styles from "./algoliaSearchBox.module.scss";

const CustomSearchBox = (props) => {
  const { refine, currentRefinement } = props;

  const router = useRouter();

  const [search, setSearch] = useState(router.query.leit || currentRefinement);

  const handleChange = (e) => {
    setSearch(e.target.value);
    refine(e.target.value);
  };

  useEffect(() => {
    refine(router.query.leit);
  }, []);

  return (
    <div className={styles["search-container"]}>
      <Input
        type="text"
        className={styles["searchBox"]}
        value={search}
        onChange={handleChange}
        placeholder="Hverju ertu að leita af?"
      />
    </div>
  );
};

const AlgoliaSearchBox = connectSearchBox(CustomSearchBox);

export { AlgoliaSearchBox };
