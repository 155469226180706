import "./orderDetailsAddressSection.module.scss";

import React, { FC } from "react";

import { WooBilling, WooShipping } from "@kaktus-kreatives/ecommerce";

type OrderDetailsAddressSectionProps = {
  title: string;
  address: WooBilling | WooShipping;
};

const OrderDetailsAddressSection: FC<OrderDetailsAddressSectionProps> = ({
  address,
  title,
}) => (
  <div className="order-address-section">
    <h3>{title}</h3>
    <div>{address["company"]}</div>
    <div>
      {address["first_name"]} {address["last_name"]}
    </div>
    <div>
      {address["address_1"]}, {address["address_2"]}
    </div>
    <div>
      {address["postcode"]} {address["city"]}
    </div>
    <div>{address["country"] === "IS" ? "Ísland" : address["country"]}</div>
    <div>{address["phone"]}</div>
    <div>{address["email"]}</div>
  </div>
);

export { OrderDetailsAddressSection };
