import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import { useRouter } from "next/router";
import React, { FC, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import Icon from "@ant-design/icons";
import CartSVG from "@assets/svg/cart.svg";
import HelpSVG from "@assets/svg/help.svg";
import LogoutSVG from "@assets/svg/logout.svg";
import OrdersSVG from "@assets/svg/orders.svg";
import OtcSVG from "@assets/svg/otc.svg";
import PrescriptionSVG from "@assets/svg/prescription.svg";
import SettingsSVG from "@assets/svg/settings.svg";
import StatusSVG from "@assets/svg/status.svg";
import { CartQuantityBadge } from "@components/atoms";
import { useCart } from "@kaktus-kreatives/ecommerce";
import { logout } from "@query-api";
import { usePrescriptionCartData } from "@query-api/cart";
import { routes } from "@utils/routes";

const { Item, Divider } = Menu;

const Navigation: FC = () => {
  const router = useRouter();
  const [current, setCurrent] = useState("");
  const queryClient = useQueryClient();
  useEffect(() => {
    const getCurrentRoute = () => {
      for (const [key] of Object.entries(routes)) {
        if (router.asPath === "/") {
          return "/lyfsedlar";
        }

        if (router.asPath.includes(routes[key].path)) {
          return routes[key].path;
        }
      }
      return "404";
    };

    setCurrent(getCurrentRoute());
  }, [router.pathname]);

  const { mutate: mutateLogout } = useMutation(() => logout(queryClient), {
    onSettled: () => {
      router.push("/innskraning");
    },
  });

  const handleClick = (e: any) => {
    setCurrent(e.key);
    // TODO: Handle logout with just a tad more sophistication 😘
    e.key === "logout" ? mutateLogout() : router.push(e.key);
  };

  const PrescriptionIcon = (props: any) => (
    <Icon component={PrescriptionSVG} {...props} />
  );
  const ProductsIcon = (props: any) => <Icon component={OtcSVG} {...props} />;
  const OrdersIcon = (props: any) => <Icon component={OrdersSVG} {...props} />;
  const CartIcon = (props: any) => <Icon component={CartSVG} {...props} />;
  const StatusIcon = (props: any) => <Icon component={StatusSVG} {...props} />;
  const SettingsIcon = (props: any) => (
    <Icon component={SettingsSVG} {...props} />
  );
  const HelpIcon = (props: any) => <Icon component={HelpSVG} {...props} />;
  const LogoutIcon = (props: any) => <Icon component={LogoutSVG} {...props} />;

  const { data: cartData } = useCart();
  const { data: prescriptionCart } = usePrescriptionCartData();

  return (
    <Sider breakpoint="lg" collapsedWidth="100" width="27rem">
      <Menu
        onClick={handleClick}
        selectedKeys={[current]}
        mode="inline"
        className="main-navigation"
      >
        <Item
          key={routes.prescriptionCustomers.path}
          icon={<PrescriptionIcon />}
        >
          <span className="main-navigation-item-label">Lyfseðlar</span>
        </Item>
        <Item key={routes.products.path} icon={<ProductsIcon />}>
          <span className="main-navigation-item-label">Vörur</span>
        </Item>
        <Item key={routes.orders.path} icon={<OrdersIcon />}>
          <span className="main-navigation-item-label">Pantanir</span>
        </Item>
        <Item key={routes.status.path} icon={<StatusIcon />}>
          <span className="main-navigation-item-label">Þrepastaða SÍ</span>
        </Item>
        <Item key={routes.cart.path} icon={<CartIcon />}>
          <CartQuantityBadge
            cart={cartData}
            prescriptionCart={prescriptionCart}
            isMobile={false}
          />
          <span className="main-navigation-item-label">Karfa</span>
        </Item>
        <Divider />
        <Item key={routes.settings.path} icon={<SettingsIcon />}>
          <span className="main-navigation-item-label">Stillingar</span>
        </Item>
        <Item key={routes.help.path} icon={<HelpIcon />}>
          <span className="main-navigation-item-label">Hjálp</span>
        </Item>
        <Item key="logout" icon={<LogoutIcon />}>
          <span className="main-navigation-item-label">Útskráning</span>
        </Item>
      </Menu>
    </Sider>
  );
};

export { Navigation };
