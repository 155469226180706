import React, { FC } from "react";
import { Collapse } from "antd";

import { MedioPrescription } from "@types";
import { formatDateShort } from "@utils";

import styles from "./prescriptionActions.module.scss";

const { Panel } = Collapse;

interface PrescriptionActions {
  prescription: MedioPrescription;
  extra: any;
}

const PrescriptionActions: FC<PrescriptionActions> = ({
  prescription,
  extra,
}) => {
  return (
    <div className={styles["prescription-actions"]}>
      <Collapse defaultActiveKey={["0"]} ghost expandIcon={() => null}>
        <Panel header="" key="1" extra={extra}>
          <div className={styles["prescription-details"]}>
            <div
              className={`${styles["prescription-details-item"]} ${styles["instructionsForUse"]}`}
            >
              Notkunarleiðbeiningar:{" "}
              <span>{prescription.instructionsForUse}</span>
            </div>
            <div
              className={`${styles["prescription-details-item"]} ${styles["doctor"]}"]`}
            >
              Læknir: <span>{prescription.doctor.name}</span>
            </div>
            <div
              className={`${styles["prescription-details-item"]} ${styles["createDate"]}]`}
            >
              Gefið út: <span>{formatDateShort(prescription.createDate)}</span>
            </div>
            <div
              className={`${styles["prescription-details-item"]} ${styles["latestDispense"]}]`}
            >
              Gildir til:{" "}
              <span>{formatDateShort(prescription.latestDispense)}</span>
            </div>
            <div
              className={`${styles["prescription-details-item"]} ${styles["earliestDispense"]}"]`}
            >
              Má afgreiða í fyrsta lagi:{" "}
              <span>{formatDateShort(prescription.earliestDispense)}</span>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export { PrescriptionActions };
