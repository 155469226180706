import React from "react";
import styles from "./siStatusSkeletons.module.scss";
import { Skeleton } from "antd";
import { SiStatusTableHeader } from "..";

const SiStatusSkeletons = () => {
  return (
    <div className="si-status">
      <SiStatusTableHeader />
      <div className="si-status-items">
        {Array(4)
          .fill("")
          .map((_, index) => (
            <div
              className={`si-status-item ${styles["si-status-item-skeleton"]}`}
              key={index}
            >
              <Skeleton title={false} paragraph={{ rows: 3 }} active />
              <Skeleton
                className={styles["mobile-only"]}
                title={false}
                paragraph={{ rows: 3 }}
                active
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export { SiStatusSkeletons };
