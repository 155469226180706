import { Button, Checkbox, Form, Input } from "antd";
import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { UserUpdateFormSkeleton } from "@components/molecules";
import {
  toastMessage,
  useUser,
  WooCheckoutUser,
  WooCustomer
} from "@kaktus-kreatives/ecommerce";

import { CheckoutFooter } from "../checkoutFooter/checkoutFooter";
import styles from "./userUpdateForm.module.scss";

const { Item } = Form;
const { Group } = Input;

const formDataToUserData = (formData) => {
  // TODO: Maybe extract to utils function in utils
  return {
    billing: {
      email: formData.billing_email,
      phone: formData.billing_phone,
      first_name: formData.billing_first_name,
      last_name: formData.billing_last_name,
      address_1: formData.billing_address_1,
      city: formData.billing_city,
      postcode: formData.billing_postcode,
      company: formData.billing_company,
    },
    shipping: {
      address_1: formData.shipping_address_1,
      city: formData.shipping_city,
      postcode: formData.shipping_postcode,
      company: formData.shipping_company,
    },
  } as WooCheckoutUser;
};

const userDataToFormData = (user: WooCheckoutUser) => {
  // TODO: Maybe extract to utils function in utils
  if (!user || !user.billing) return;

  return {
    billing_email: user.billing.email,
    billing_phone: user.billing.phone,
    billing_first_name: user.billing.first_name,
    billing_last_name: user.billing.last_name,
    billing_address_1: user.billing.address_1,
    billing_city: user.billing.city,
    billing_postcode: user.billing.postcode,
    billing_company: user.billing.company,
    shipping_address_1: user.shipping.address_1,
    shipping_city: user.shipping.city,
    shipping_postcode: user.shipping.postcode,
    shipping_company: user.shipping.company,
  };
};

const UserUpdateForm: FC<{
  isCheckout?: boolean;
  checkoutPrevious?: any;
  checkoutNext?: any;
  checkoutButtonText?: string;
  setFormValues?: any;
}> = ({
  isCheckout,
  checkoutPrevious,
  checkoutNext,
  checkoutButtonText,
  setFormValues,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(
    form.getFieldsError().some(({ errors }) => errors.length) ||
      Object.entries(form.getFieldsValue()).some(
        ([value, key]) =>
          typeof value === "string" &&
          value.length === 0 &&
          key !== "billing_company"
      )
  );

  const [shipmentOther, setShipmentOther] = useState(false);
  const queryClient = useQueryClient();

  const { userData, isSuccess, mutateUser } = useUser();

  useEffect(() => {
    if (userData) {
      setShipmentOther(userData.hasShipmentOther);
      setLoading(false);
    }
  }, [userData]);

  if (!isSuccess) {
    return <UserUpdateFormSkeleton />;
  }

  const handleSubmit = async (formValues: any) => {
    const userInfo = formDataToUserData(formValues);

    if (!shipmentOther) {
      delete userInfo.shipping;
    }

    try {
      setLoading(true);
      await mutateUser({
        ...userInfo,
        hasShipmentOther: shipmentOther,
      });

      // Update localStorage shipmentOther value
      if (shipmentOther) {
        localStorage.setItem("shipmentOther", "true");
      } else {
        localStorage.setItem("shipmentOther", "false");
      }

      setLoading(false);
      // Checkoutnext only exists on /checkout pages
      if (checkoutNext) {
        // TODO: Maybe setFormvalues?
        checkoutNext();
      }
    } catch (e) {
      toastMessage("error", e.message.message);
      setLoading(false);
    }
  };

  // Checking validation to set or unset button as disabled
  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const someValueIsEmpty = Object.entries(form.getFieldsValue()).some(
      ([value, key]) =>
        typeof value === "string" &&
        value.length === 0 &&
        key !== "billing_company"
    );

    setIsDisabled(hasErrors || someValueIsEmpty);
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        scrollToFirstError
        initialValues={userDataToFormData(userData)}
        onValuesChange={(_, data) =>
          setFormValues ? setFormValues(data) : null
        }
        onFieldsChange={handleFormChange}
        className="medio-content-box rounded"
      >
        <Group className={styles["user-update-form"]}>
          <Item
            label="Netfang"
            required
            name="billing_email"
            className={styles["email"]}
            rules={[
              {
                required: true,
                type: "email",
              },
            ]}
          >
            <Input placeholder="Netfang" />
          </Item>
          <Item
            label="Sími"
            required
            name="billing_phone"
            className={styles["phone"]}
            rules={[
              {
                required: true,
                pattern: /^[\d ()+-]+$/,
              },
            ]}
          >
            <Input placeholder="Sími" />
          </Item>
          <Item
            label="Nafn"
            required
            name="billing_first_name"
            className={styles["first-name"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Nafn" />
          </Item>
          <Item
            label="Eftirnafn"
            required
            name="billing_last_name"
            className={styles["last-name"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Eftirnafn" />
          </Item>
          <Item
            label="Heimilisfang"
            required
            name="billing_address_1"
            className={styles["address-1"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Heimilisfang" />
          </Item>
          <Item
            label="Staður"
            required
            name="billing_city"
            className={styles["city"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Staður" />
          </Item>
          <Item
            label="Póstnúmer"
            required
            name="billing_postcode"
            className={styles["postcode"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Póstnúmer" />
          </Item>
          <Item
            label="Fyrirtæki"
            name="billing_company"
            className={styles["company"]}
          >
            <Input placeholder="Fyrirtæki" />
          </Item>
          <Item
            label="Senda annað?"
            name="billing_shipmentOther"
            className={styles["shipment-other"]}
          >
            <Checkbox
              checked={shipmentOther}
              onChange={(e) => setShipmentOther(e.target.checked)}
            />
          </Item>
        </Group>
        {shipmentOther && (
          <Group className={styles["user-shipping"]}>
            <Item
              label="Heimilisfang"
              required
              name="shipping_address_1"
              className={styles["address-1"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Heimilisfang" />
            </Item>
            <Item
              label="Staður"
              required
              name="shipping_city"
              className={styles["city"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Staður" />
            </Item>
            <Item
              label="Póstnúmer"
              required
              name="shipping_postcode"
              className={styles["postcode"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Póstnúmer" />
            </Item>
            <Item
              label="Fyrirtæki"
              name="shipping_company"
              className={styles["company"]}
            >
              <Input placeholder="Fyrirtæki" />
            </Item>
          </Group>
        )}
        {!isCheckout && (
          <Item className={styles["save"]}>
            <Button
              type="primary"
              className={classNames("primary", { disabled: isDisabled })}
              size="large"
              htmlType="submit"
              disabled={isDisabled}
              loading={loading}
            >
              Vista
            </Button>
          </Item>
        )}
      </Form>
      {isCheckout && (
        <CheckoutFooter
          previousAction={checkoutPrevious}
          nextAction={async () => {
            await form.validateFields();
            form.submit();
          }}
          buttonText={checkoutButtonText}
          isLoading={loading}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
};

export { UserUpdateForm };
