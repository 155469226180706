import { Menu } from "antd";
import classnames from "classnames";
import { useRouter } from "next/router";
import React, { FC, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import Icon from "@ant-design/icons";
import CartSVG from "@assets/svg/mobile/cart.svg";
import ExpandSVG from "@assets/svg/mobile/expand.svg";
import HelpSVG from "@assets/svg/mobile/help.svg";
import LogoutSVG from "@assets/svg/mobile/logout.svg";
import OrdersSVG from "@assets/svg/mobile/orders.svg";
import OtcSVG from "@assets/svg/mobile/otc.svg";
// Mobile SVGS are needded for unique gradient IDs in defs
import PrescriptionSVG from "@assets/svg/mobile/prescription.svg";
import SettingsSVG from "@assets/svg/mobile/settings.svg";
import StatusSVG from "@assets/svg/mobile/status.svg";
import { CartQuantityBadge } from "@components/atoms";
import { useCart } from "@kaktus-kreatives/ecommerce";
import { logout } from "@query-api";
import { usePrescriptionCartData } from "@query-api/cart";
import { routes } from "@utils/routes";

const { Item } = Menu;

const MobileNavigation: FC = () => {
  const router = useRouter();
  const [isToggled, setToggled] = useState(false);
  const [current, setCurrent] = useState("");

  const queryClient = useQueryClient();
  const { data: cartData } = useCart();
  const { data: prescriptionCartData } = usePrescriptionCartData();
  useEffect(() => {
    const getCurrentRoute = () => {
      for (const [key] of Object.entries(routes)) {
        if (router.asPath === "/") {
          return "/lyfsedlar";
        }

        if (router.asPath.includes(routes[key].path)) {
          return routes[key].path;
        }
      }
      return "404";
    };

    setCurrent(getCurrentRoute());
  }, [router.pathname]);

  const { mutate: mutateLogout } = useMutation(() => logout(queryClient), {
    onSettled: () => {
      router.push("/innskraning");
    },
  });

  const handleClick = (e: any) => {
    if (e.key === "expand") {
      return setToggled(!isToggled);
    }
    setToggled(false);
    if (e.key === "logout") {
      return mutateLogout();
    }
    // setCurrent(e.key) // Uncomment for reactivity
    return router.push(e.key);
  };

  const PrescriptionIcon = (props: any) => (
    <Icon component={PrescriptionSVG} {...props} />
  );
  const ProductsIcon = (props: any) => <Icon component={OtcSVG} {...props} />;
  const OrdersIcon = (props: any) => <Icon component={OrdersSVG} {...props} />;
  const CartIcon = (props: any) => <Icon component={CartSVG} {...props} />;
  const StatusIcon = (props: any) => <Icon component={StatusSVG} {...props} />;
  const SettingsIcon = (props: any) => (
    <Icon component={SettingsSVG} {...props} />
  );
  const HelpIcon = (props: any) => <Icon component={HelpSVG} {...props} />;
  const LogoutIcon = (props: any) => <Icon component={LogoutSVG} {...props} />;

  return (
    <>
      {isToggled && (
        <div
          className="main-mobile-navigation-outside"
          onClick={() => setToggled(false)}
        />
      )}
      <div className={classnames("main-mobile-navigation", { isToggled })}>
        <Menu
          onClick={handleClick}
          selectedKeys={[routes.prescriptionCustomers.path]} // Replace with [current] for reactivity
          mode="inline"
          className="main-mobile-navigation-top"
        >
          <Item key={routes.cart.path} icon={<CartIcon />}>
            <CartQuantityBadge
              cart={cartData}
              prescriptionCart={prescriptionCartData}
              isMobile={true}
            />
            Karfa
          </Item>
          <Item
            key={routes.prescriptionCustomers.path}
            icon={<PrescriptionIcon />}
          >
            Lyfseðlar
          </Item>
          <Item
            key="expand"
            className="mobile-expand"
            icon={<Icon component={ExpandSVG} />}
          >
            Valmynd
          </Item>
        </Menu>
        <Menu
          onClick={handleClick}
          selectedKeys={[]}
          mode="inline"
          className="main-mobile-navigation-bottom"
        >
          <Item key={routes.orders.path} icon={<OrdersIcon />}>
            Pantanir
          </Item>
          <Item key={routes.products.path} icon={<ProductsIcon />}>
            Vörur
          </Item>
          <Item key={routes.status.path} icon={<StatusIcon />}>
            Þrepastaða SÍ
          </Item>
          <Item key={routes.settings.path} icon={<SettingsIcon />}>
            Stillingar
          </Item>
          <Item key={routes.help.path} icon={<HelpIcon />}>
            Hjálp
          </Item>
          <Item key="logout" icon={<LogoutIcon />}>
            Útskráning
          </Item>
        </Menu>
      </div>
    </>
  );
};

export { MobileNavigation };
