import classNames from "classnames";
import React, { FC } from "react";
import Lottie from "react-lottie-player";

import animationData from "@assets/lottie/drugLoader.json";

import styles from "./medioLoader.module.scss";

interface MedioLoaderProps {
  loading: boolean;
  title: string;
  subtitle?: string;
  isPrescription?: boolean;
  lottieFile?: any;
  noBackground?: boolean;
}

const MedioLoader: FC<MedioLoaderProps> = ({
  loading,
  title,
  subtitle,
  isPrescription,
  lottieFile,
  noBackground,
}) => {
  if (!loading) {
    return null;
  }

  return (
    <div
      className={classNames(styles["medio-loading"], {
        "prescription-loader": isPrescription,
        [styles["no-background"]]: noBackground,
      })}
    >
      <Lottie
        loop
        play
        animationData={lottieFile ? lottieFile : animationData}
        audioFactory={null}
      />
      <h3 className={styles["title"]}>{title}</h3>
      <h4 className={styles["subtitle"]}>{subtitle}</h4>
    </div>
  );
};

export { MedioLoader };
