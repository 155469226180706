import React, { FC } from "react";
import { Button } from "antd";

import { MedioPrescription } from "@types";
import { isDateAfter } from "@utils";

type PrescriptionGenericsButtonProps = {
  prescription: MedioPrescription;
  openGenericsModal: (e: React.MouseEvent) => void;
};

const PrescriptionGenericsButton: FC<PrescriptionGenericsButtonProps> = ({
  prescription,
  openGenericsModal,
}) => {
  if (
    !prescription ||
    prescription.genericDrugs.length < 1 ||
    prescription.rSigned ||
    isDateAfter(prescription.earliestDispense)
  ) {
    return null;
  }

  // TODO: Handle is date after

  return (
    <Button
      className="prescription-generics-button primary"
      size="large"
      onClick={openGenericsModal}
    >
      Samheitalyf
    </Button>
  );
};

export { PrescriptionGenericsButton };
