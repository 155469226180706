import classNames from "classnames";
import AutoHeight from "embla-carousel-auto-height";
import ClassNames from "embla-carousel-class-names";
import useEmblaCarousel from "embla-carousel-react";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import React, { FC, useCallback, useEffect, useState } from "react";

import { Product, ProductGrid } from "@components/molecules";
import { useCart } from "@kaktus-kreatives/ecommerce";
import { getCartQuantity } from "@utils";

import { DotButton, NextButton, PrevButton } from "./product-carousel-buttons";

const ProductCarousel: FC<any> = (props) => {
  const [emblaRef, embla] = useEmblaCarousel(
    {
      dragFree: true,
      align: 0,
      containScroll: "trimSnaps",
      inViewThreshold: 0.5,
      slidesToScroll: 2,
    },
    [
      AutoHeight(),
      WheelGesturesPlugin(),
      ClassNames({
        selected: "embla__viewport-is-selected",
        draggable: "embla__viewport-is-draggable",
        dragging: "embla__viewport-is-dragging",
      }),
    ]
  );
  const { data: cart } = useCart();

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  const { products, classnames, cardClassnames } = props;

  if (products.length <= 3) {
    {
      return <ProductGrid hits={products} />;
    }
  }

  return (
    <div className={classNames("ke-product-carousel", classnames, "embla")}>
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {products.map((product: any, index: number) => (
            <Product
              key={index}
              product={product}
              cartQuantity={getCartQuantity(product, cart)}
              classnames={[cardClassnames, "embla__slide", "carousel-item"]}
            />
          ))}
        </div>
        <div className="embla__buttons">
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export { ProductCarousel };
