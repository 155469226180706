import axios from "axios";
import { QueryClient, useQuery } from "react-query";

import { toastMessage } from "@kaktus-kreatives/ecommerce";
import {
  AddPrescriptionToCartProps,
  MedioPrescriptionCart,
  SwapToCartProps
} from "@types";
import { formatRXAResponseToWooResponse, handleMedioApiError } from "@utils";

const getPrescriptionsFromLocalStorage = () => {
  const prescriptionCart = JSON.parse(localStorage.getItem("prescriptionCart"));

  return prescriptionCart;
};

const getPrescriptionCartData = async () => {
  try {
    const prescriptionCartData = getPrescriptionsFromLocalStorage();

    const prescriptionsToGet =
      prescriptionCartData && prescriptionCartData.line_items
        ? prescriptionCartData.line_items
        : [];

    if (prescriptionsToGet.length === 0) {
      const newCart = formatRXAResponseToWooResponse({});
      localStorage.setItem("prescriptionCart", JSON.stringify(newCart));
      return newCart;
    }

    const { data } = await axios.post(
      "/api/cart/prescriptions",
      prescriptionsToGet
    );

    return formatRXAResponseToWooResponse(data);
  } catch (e) {
    handleMedioApiError(e);
    // throw e.response.data;
  }
};

const clearPrescriptionCartData = (queryClient: QueryClient) => {
  const newCart = {
    line_items: [],
    total_quantity: 0,
    total: 0,
    subtotal: 0,
    total_tax: 0,
  };

  localStorage.setItem("prescriptionCart", JSON.stringify(newCart));
  queryClient.invalidateQueries(["prescriptionCart"]);
  queryClient.invalidateQueries(["wooCart"]);
};

const updateLocalCart = (data, line_items) => {
  const newCart = { ...getPrescriptionsFromLocalStorage() };

  newCart.line_items = line_items;
  newCart.total_quantity = data.numberOfPackagesInOrder;
  newCart.total = data.price?.price;
  newCart.subtotal = data.price?.priceWithoutVat;
  newCart.total_tax = data.price?.vatAmount;

  localStorage.setItem("prescriptionCart", JSON.stringify(newCart));
};

const addPrescriptionToCart = async ({
  prescription,
  kennitala,
  queryClient,
  generic,
}: AddPrescriptionToCartProps) => {
  const cartData = getPrescriptionsFromLocalStorage();

  // Do nothing if already in cart
  if (
    !cartData ||
    cartData!.line_items.filter(
      (item: any) => item.prescriptionNumber === prescription.prescriptionNumber
    ).length > 0
  ) {
    return;
  }

  let drugToAdd = prescription.drug;
  if (generic) {
    drugToAdd = generic;
  }
  const product = {
    prescriptionNumber: prescription.prescriptionNumber,
    nvnr: drugToAdd.nvnr,
    numberOfPackages: drugToAdd.numberOfPackages,
    idDoctor: prescription.doctor.id,
    kennitala,
  };

  const tmpPrescriptions = [...cartData.line_items, product];

  try {
    const { data } = await axios.post(
      "/api/cart/prescriptions",
      tmpPrescriptions
    );

    const newCart = { ...cartData };

    newCart.line_items = tmpPrescriptions;
    newCart.total_quantity = data.numberOfPackagesInOrder;
    newCart.total = data.price?.price;
    newCart.subtotal = data.price?.priceWithoutVat;
    newCart.total_tax = data.price?.vatAmount;

    localStorage.setItem("prescriptionCart", JSON.stringify(newCart));
    await queryClient.invalidateQueries(["prescriptionCart"]);
    await queryClient.invalidateQueries(["wooCart"]);
    toastMessage("success", "Lyfseðli bætt í körfu.", {
      id: "prescriptionAddToCartSuccess",
    });
    return data;
  } catch (e) {
    handleMedioApiError(e);
    toastMessage("error", "Ekki tókst að bæta lyfseðli í körfu.", {
      id: "prescriptionAddToCartError",
    });
    // console.log(e);
    throw e.response.data;
  }
};

const removePrescriptionFromCart = async (
  prescriptionNumber: any,
  queryClient: QueryClient
) => {
  const prescriptionCart = getPrescriptionsFromLocalStorage();

  const filteredLineItems = prescriptionCart.line_items.filter(
    (item) => item.prescriptionNumber !== prescriptionNumber
  );

  const tmpPrescriptionCart = { ...prescriptionCart };

  tmpPrescriptionCart.line_items = filteredLineItems;
  try {
    await axios.patch("/api/cart/fees", {
      prescription: `Lyfseðill #${prescriptionNumber}`,
    });
    localStorage.setItem(
      "prescriptionCart",
      JSON.stringify(tmpPrescriptionCart)
    );

    await queryClient.invalidateQueries(["prescriptionCart"]);
    await queryClient.invalidateQueries(["wooCart"]);
    toastMessage("success", "Vöru eytt úr körfu.", {
      id: "cart-update",
    });
  } catch (e) {
    handleMedioApiError(e);
    toastMessage("error", "Ekki tókst að eyða lyfseðli úr körfu.", {
      id: "cart-error",
    });
    throw e.response.data;
  }
};

const swapToCart = async (drugData: SwapToCartProps) => {
  const prescriptionCart = getPrescriptionsFromLocalStorage();

  const tmpCart = { ...prescriptionCart };

  const prescriptionIndex = prescriptionCart.line_items.findIndex(
    (item) => item.prescriptionNumber === drugData.id
  );

  tmpCart.line_items[prescriptionIndex].nvnr = drugData.nvnr;

  try {
    const { data } = await axios.post(
      "/api/cart/prescriptions",
      tmpCart.line_items
    );

    updateLocalCart(data, tmpCart.line_items);
    toastMessage("success", "Samheitalyf uppfært.", {
      id: "prescription-update",
    });
    return data;
  } catch (e) {
    handleMedioApiError(e);
    toastMessage("error", "Ekki tókst að skipta út samheitalyfi.", {
      id: "cart-error",
    });
    throw e.response.data;
  }
};

const usePrescriptionCartData = () => {
  return useQuery<MedioPrescriptionCart, Error>({
    queryKey: ["prescriptionCart"],
    queryFn: () => getPrescriptionCartData(),
  });
};

export {
  usePrescriptionCartData,
  addPrescriptionToCart,
  swapToCart,
  getPrescriptionCartData,
  removePrescriptionFromCart,
  clearPrescriptionCartData,
};
