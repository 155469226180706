import axios from "axios";
import { useQuery } from "react-query";

import { MedioWooOtcProduct } from "@types";
import { handleMedioApiError } from "@utils";

const getProductsData = async (): Promise<MedioWooOtcProduct[]> => {
  try {
    const { data } = await axios("/api/products");
    return data;
  } catch (e) {
    handleMedioApiError(e);
    throw e.response.data;
  }
};

const useProductsData = () => {
  return useQuery<MedioWooOtcProduct[], Error>({
    queryKey: ["wooProducts"],
    queryFn: () => getProductsData(),
    retry: false,
  });
};

export { useProductsData, getProductsData };
