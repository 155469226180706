import { Alert } from "antd";
import React, { FC } from "react";

import { WooCart, WooCartItem } from "@kaktus-kreatives/ecommerce";

import styles from "./cartNotices.module.scss";

interface CartNoticesProps {
  cart: WooCart;
}

const hasControlledInCart = (cart: WooCart) => {
  return (
    cart.line_items.filter(
      (item: WooCartItem) => item.product.meta_data?.controlled_drug
    ).length > 0
  );
};

const CartNotices: FC<CartNoticesProps> = ({ cart }) => {
  if (!cart) {
    return null;
  }

  return (
    <div className={styles["cart-notices"]}>
      {hasControlledInCart(cart) && (
        <Alert
          type="info"
          message="Ekki er hægt að póstsenda þessa pöntun þar sem eftirritunarskylt lyf er í körfu ⚠️"
        />
      )}
    </div>
  );
};

export { CartNotices };
