import { AxiosError } from "axios";
import Router from "next/router";

import { toastMessage } from "@kaktus-kreatives/ecommerce";

import { sentryExceptionHandler } from "./sentry";

const handleMedioApiError = (e: AxiosError) => {
  // message needs access to browser globals
  if (typeof window === "undefined") {
    return null;
  }

  toastMessage("dismiss");

  const { status } = e.response;

  if (status === 401 || status === 403) {
    if (!Router.router.asPath.includes("/innskraning")) {
      Router.push("/innskraning");
      return toastMessage(
        "error",
        "Innskráning rann út á tíma, vinsamlegast skráðu þig inn aftur. Villukóði: 41",
        {
          id: "login-expired",
        }
      );
    }
  }
  sentryExceptionHandler(e);

  toastMessage(
    "error",
    `${e.response.data.message} ${
      e.response.data.code ? `Villukóði: ${e.response.data.code}` : ""
    }`,
    {
      id: "error",
    }
  );
};

export { handleMedioApiError };
