import { Input } from "antd";
import classNames from "classnames";
import Link from "next/link";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { FiLoader, FiTrash2 } from "react-icons/fi";
import { useDebounce } from "react-use";

import { toastMessage, useUpdateCart } from "@kaktus-kreatives/ecommerce";
import { MedioWooCartItem, MedioWooCartItemProduct } from "@types";
import { formatPrice } from "@utils";

import styles from "./cartItem.module.scss";

interface CartItemProps {
  item: MedioWooCartItem;
}

const CartItem: FC<CartItemProps> = ({ item }) => {
  const [loading, setLoading] = useState(false);
  const [quantityValue, setQuantityValue] = useState(item.quantity);
  const updateCart = useUpdateCart();

  const getImageUrl = (product: MedioWooCartItemProduct) => {
    if (typeof window !== "undefined") {
      if (window.innerWidth < 768) {
        return product.image;
      }
    }
    return product.imageSmall;
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value?.length) {
      setQuantityValue(null);
      return;
    }
    let value = parseInt(e.target.value);
    if (isNaN(value)) {
      return;
    }

    value = Math.round(value);
    if (value > item.product.stock) {
      setQuantityValue(item.product.stock);
      toastMessage(
        "error",
        `Ekki hægt að panta meira en ${item.product.stock} stk`,
        {
          id: "out-of-stock",
        }
      );
      return;
    }

    if (value >= 0) {
      setQuantityValue(value);
    }
  };

  // prevent request spam
  useDebounce(
    () => {
      if (quantityValue !== null && quantityValue !== item.quantity) {
        updateCart(item.product.id, quantityValue, { replace: true });
      }
    },
    200,
    [quantityValue]
  );
  // update quantity state with correct data
  useEffect(() => {
    if (quantityValue !== item.quantity) {
      setQuantityValue(item.quantity);
    }
  }, [item.quantity]);
  // if input is empty and then loses focus we set quantity state to cartQuantity (reset)
  const handleInputBlur = () => {
    if (quantityValue === null) {
      setQuantityValue(item.quantity);
    }
  };

  return (
    <div className={styles["cart-item"]}>
      <div className={styles["image"]}>
        <img src={getImageUrl(item.product)} alt={`${item.product.name}`} />
      </div>
      <div className={styles["name"]}>
        <span className={styles["label"]}>Heiti</span>
        <div className={styles["name-title"]}>
          <Link href={`/vorur/${item.product.slug}`}>
            <a>{item.product.name}</a>
          </Link>
        </div>
        <div className={styles["name-description"]}>
          {item.product.description}
        </div>
      </div>
      <div className={styles["description"]}>
        <span className={styles["label"]}>Lýsing</span>
        {item.product.description}
      </div>
      <div className={styles["fullprice"]}>
        <span className={styles["label"]}>Verð</span>
        {formatPrice(item.product.price)}
      </div>
      <div className={styles["quantity"]}>
        <span className={styles["label"]}>Magn</span>
        {/* {item.quantity} */}
        <Input
          value={quantityValue}
          onChange={handleChangeInput}
          className={styles["quantity"]}
          onBlur={handleInputBlur}
          max={item.product.stock}
        />
      </div>
      <div className={styles["price"]}>
        <span className={styles["label"]}>Samtals</span>
        <div className="cart-Items-header-action"></div>
        {formatPrice(item.line_subtotal + item.line_subtotal_tax)}
      </div>
      <div className={styles["action"]}>
        <span className={styles["label"]}>Eyða</span>
        <button
          onClick={() => updateCart(item.product_id, 0, { replace: true })}
        >
          {loading ? (
            <FiLoader
              size={20}
              stroke="#888"
              className={classNames({ spin: loading })}
            />
          ) : (
            <FiTrash2
              size={20}
              stroke="#888"
              className={classNames({ spin: loading })}
            />
          )}
        </button>
      </div>
    </div>
  );
};

export { CartItem };
