import { Skeleton } from "antd";
import classNames from "classnames";
import React, { FC } from "react";
import { FiLoader, FiTrash2 } from "react-icons/fi";
import { QueryClient, useQueryClient } from "react-query";

import { usePrescriptionCartData } from "@query-api/cart";
import { MedioPrescriptionCartItem } from "@types";
import { formatPrice, getRXADrugDescription } from "@utils";

import styles from "./cartPrescriptionTable.module.scss";

interface CartPrescriptionTableProps {
  items: MedioPrescriptionCartItem[];
  deleteAction: (prescriptionNumber: string, queryClient: QueryClient) => void;
  loading: boolean;
}

const CartPrescriptionTable: FC<CartPrescriptionTableProps> = ({
  items,
  deleteAction,
  loading,
}) => {
  if (items && items.length < 1) {
    return null;
  }

  const queryClient = useQueryClient();

  const { isFetching } = usePrescriptionCartData();
  return (
    <div className={styles["cart-prescriptions"]}>
      <h3 className={styles["main-title"]}>Lyfseðlar</h3>
      <div className={styles["header"]}>
        <div className={styles["image"]}>Lyfseðlar</div>
        <div className={styles["name"]}>Heiti</div>
        <div className={styles["fullprice"]}>Fullt verð</div>
        <div className={styles["siamount"]}>Hluti SÍ</div>
        <div className={styles["discount"]}>Afsláttur</div>
        <div className={styles["price"]}>Verð</div>
        <div className={styles["action"]}></div>
      </div>
      <div className={styles["items"]}>
        {items &&
          items.map((item: any, index: number) => {
            const { selectedDrug, price } = item;
            return (
              <div className={styles["cart-item"]} key={index}>
                <div className={styles["image"]}>
                  <img
                    src={
                      "https://base.medio.is/wp-content/uploads/2021/01/prescription-icon.png"
                    }
                    alt={`Lyfseðill: ${selectedDrug.name}`}
                  />
                </div>
                <div className={styles["name"]}>
                  <span className={styles["label"]}>Heiti</span>
                  <div className={styles["name-title"]}>
                    {selectedDrug.name}
                  </div>
                  <div className={styles["name-description"]}>
                    {getRXADrugDescription(selectedDrug)}
                  </div>
                </div>
                <div className={styles["description"]}>
                  <span className={styles["label"]}>Lýsing</span>
                  {getRXADrugDescription(selectedDrug)}
                </div>
                <div className={styles["fullprice"]}>
                  <span className={styles["label"]}>Fullt verð</span>
                  {isFetching ? (
                    <Skeleton paragraph={{ rows: 1 }} title={false} active />
                  ) : (
                    formatPrice(
                      +price.price + +price.discount + +price.SIAmount
                    )
                  )}
                </div>
                <div className={styles["siamount"]}>
                  <span className={styles["label"]}>Hluti SÍ</span>
                  {isFetching ? (
                    <Skeleton paragraph={{ rows: 1 }} title={false} active />
                  ) : (
                    formatPrice(price.SIAmount)
                  )}
                </div>
                <div className={styles["discount"]}>
                  <span className={styles["label"]}>Afsláttur</span>
                  {isFetching ? (
                    <Skeleton paragraph={{ rows: 1 }} title={false} active />
                  ) : (
                    formatPrice(price.discount)
                  )}
                </div>
                <div className={styles["price"]}>
                  <span className={styles["label"]}>Verð</span>

                  {/* {formatPrice(price.price)} */}
                  {isFetching ? (
                    <Skeleton paragraph={{ rows: 1 }} title={false} active />
                  ) : (
                    formatPrice(price.price)
                  )}
                </div>
                <div className={styles["action"]}>
                  <span className={styles["label"]}>Eyða</span>
                  <button
                    onClick={() =>
                      deleteAction(item.prescriptionNumber, queryClient)
                    }
                  >
                    {loading ? (
                      <FiLoader
                        size={20}
                        stroke="#888"
                        className={classNames({ spin: loading })}
                      />
                    ) : (
                      <FiTrash2
                        size={20}
                        stroke="#888"
                        className={classNames({ spin: loading })}
                      />
                    )}
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export { CartPrescriptionTable };
