import React from "react";
import skeleStyles from "./prescriptionCustomerSkeletons.module.scss";
import styles from "../../molecules/prescriptionCustomer/prescriptionCustomer.module.scss";
import { Skeleton } from "antd";

const PrescriptionCustomerSkeletons = () => {
  return (
    <>
      {Array(Math.floor(Math.random() * 5) + 1)
        .fill("")
        .map((_, index) => (
          <Skeleton
            key={index}
            className={`${styles["prescription-customer"]} ${skeleStyles["prescription-customer-skeleton"]}`}
            avatar={{ size: "large" }}
            active
            paragraph={{ rows: 2 }}
            title={{ width: 180 }}
          />
        ))}
    </>
  );
};

export { PrescriptionCustomerSkeletons };
