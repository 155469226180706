import React from "react";
import styles from "./ordersTableHeader.module.scss";

const OrdersTableHeader = () => {
  return (
    <div className={styles["orders-header"]}>
      <div className="id">Pöntun</div>
      <div className="date">Dagsetning</div>
      <div className="shipping-method">Afhendingarmáti</div>
      <div className="total">Samtals</div>
      <div className="status">Staða</div>
    </div>
  );
};

export { OrdersTableHeader };
