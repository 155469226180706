import classNames from "classnames";
import Link from "next/link";
import React, { FC } from "react";

import { ItemCartButton } from "@components/atoms";
import { MedioAlgoliaWooProduct, MedioWooOtcProduct } from "@types";
import { formatPrice, formatProductInfo } from "@utils";

import styles from "./product.module.scss";

interface OtcProductProps {
  product: any;
  // product: MedioWooOtcProduct | MedioAlgoliaWooProduct;
  cartQuantity: number;
  classnames?: string[];
}

const Product: FC<OtcProductProps> = ({
  product,
  cartQuantity,
  classnames,
}) => {
  
  const imageUrl = product.product_image || product.image;
  return (
    <div
      className={classNames(
        styles["otc-products-product"],
        "medio-content-box",
        "rounded",
        "otc-products-product",
        classnames
      )}
    >
      <Link href={`/vorur/${product.slug}`}>
        <a className={classNames(styles["link"], "link")}>
          <img
            className={classNames(styles["image"], "image")}
            src={imageUrl}
          ></img>
          <h4 className={classNames(styles["title"], "title")}>
            {product.name}
          </h4>
        </a>
      </Link>
      <div className={classNames(styles["info"], "info")}>
        {formatProductInfo(
          product.taxonomies?.pa_styrkur,
          product.taxonomies?.pa_magn
        )}
      </div>
      <div className={classNames(styles["form"], "form")}>
        {product.taxonomies?.pa_form}
      </div>
      <div className={classNames(styles["price"], "price")}>
        Verð: <span>{formatPrice(product.price)}</span>
      </div>
      <div className={classNames(styles["buttons"], "buttons")}>
        <ItemCartButton product={product} cartQuantity={cartQuantity} />
      </div>
    </div>
  );
};

export { Product };
