import { serialize } from "cookie";

import { fromBase64, toBase64 } from "./base64";

// based on: https://github.com/vercel/next.js/blob/canary/examples/api-routes-apollo-server-and-client-auth/lib/auth-cookies.js

interface RxaData {
  third_party_token: string;
  third_party_token_expiry: string;
  third_party_refresh_token: string;
  third_party_refresh_token_expiry: string;
}

const rxaKey = "rxa";
const rxaRefreshKey = "rxaRefresh";

const setRxaCookie = (res, data: RxaData) => {
  const {
    third_party_token,
    third_party_token_expiry,
    third_party_refresh_token,
    third_party_refresh_token_expiry,
  } = data;

  const cookieRxa = serialize(
    rxaKey,
    toBase64(JSON.stringify({ third_party_token, third_party_token_expiry })),
    {
      httpOnly: true,
      secure: process.env.NODE_ENV === "production",
      path: "/",
      sameSite: "lax",
    }
  );

  const cookieRxaRefresh = serialize(
    rxaRefreshKey,
    toBase64(
      JSON.stringify({
        third_party_refresh_token,
        third_party_refresh_token_expiry,
      })
    ),
    {
      httpOnly: true,
      secure: process.env.NODE_ENV === "production",
      path: "/",
      sameSite: "lax",
    }
  );

  addToCookies(cookieRxa, res);
  addToCookies(cookieRxaRefresh, res);
};

const getRxaCookieValue = (req) => {
  const cookieRxa = JSON.parse(fromBase64(req.cookies.rxa));
  const cookieRxaRefresh = JSON.parse(fromBase64(req.cookies.rxaRefresh));
  const data: RxaData = { ...cookieRxa, ...cookieRxaRefresh };

  return data;
};

const removeRxaCookies = (res) => {
  const cookieRxa = serialize(rxaKey, "", {
    maxAge: -1,
    path: "/",
  });
  const cookieRxaRefresh = serialize(rxaRefreshKey, "", {
    maxAge: -1,
    path: "/",
  });

  addToCookies(cookieRxa, res);
  addToCookies(cookieRxaRefresh, res);
};

const addToCookies = (cookieValue: string, res) => {
  let existingSetCookie =
    (res.getHeader("set-cookie") as string[] | string) ?? [];
  if (typeof existingSetCookie === "string") {
    existingSetCookie = [existingSetCookie];
  }
  res.setHeader("set-cookie", [...existingSetCookie, cookieValue]);
};

export { setRxaCookie, getRxaCookieValue, removeRxaCookies };
