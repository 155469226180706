import { Collapse } from "antd";
import React, { FC } from "react";
import { MedioHelpItem } from "@types";
const { Panel } = Collapse;

import styles from "./helpItems.module.scss";

interface HelpItemsProps {
  data: MedioHelpItem[];
}

const HelpItems: FC<HelpItemsProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <Collapse ghost expandIconPosition={"right"}>
      {data.map((item, index) => (
        <Panel
          header={item.question}
          key={index}
          className="medio-content-box rounded"
        >
          <p>{item.answer}</p>
        </Panel>
      ))}
    </Collapse>
  );
};

export { HelpItems };
