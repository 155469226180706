import axios from "axios";
import { useQuery } from "react-query";

import { MedioStatus } from "@types";
import { handleMedioApiError } from "@utils";

const getSiStatusData = async (): Promise<MedioStatus> => {
  try {
    const { data } = await axios("/api/rxa/status");
    return data;
  } catch (e) {
    handleMedioApiError(e);
    throw e.response.data;
  }
};

const useSiStatusData = () => {
  return useQuery<MedioStatus, Error>({
    queryKey: ["siStatusData"],
    queryFn: () => getSiStatusData(),
    retry: false,
  });
};

export { useSiStatusData, getSiStatusData };
