import { Steps } from "antd";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { FC, useState } from "react";

import { CartNotices, ErrorMessage, PageTitle } from "@components/atoms";
import {
  CartItemTableSkeleton,
  CheckoutPaymentMethods,
  CheckoutShippingMethods,
  CheckoutSummary,
  UserUpdateForm
} from "@components/molecules";
import {
  useCart,
  useCheckout,
  useSteps,
  useUser
} from "@kaktus-kreatives/ecommerce";
import { usePrescriptionCartData } from "@query-api/cart";

import styles from "./checkoutContent.module.scss";

const { Step } = Steps;

// TODO: Clean this mess, split into checkout step components

const CheckoutContent: FC = () => {
  const router = useRouter();

  const { userData, getGuestUserData } = useUser();
  const { currentStep, handleStepChange } = useSteps();
  const { data: cartData, status: cartStatus, error: cartError } = useCart();

  const {
    checkoutUserData,
    setCheckoutUserData,
    selectedShippingMethod,
    setSelectedShippingMethod,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    getTotalOrderAmount,
    handleShippingSubmit,
  } = useCheckout(userData);

  const { data: prescriptionCartData } = usePrescriptionCartData();

  if (
    !cartData &&
    cartData.line_items.length < 1 &&
    !prescriptionCartData &&
    prescriptionCartData.line_items.length < 1
  ) {
    router.push("/karfa");
  }

  if (cartStatus === "error") {
    return <ErrorMessage message={"Ekki tókst að sækja körfu."} />;
  }

  // TODO: Handle errors more gracefully
  if (typeof cartData === "number") {
    return <span>{`Denied!`}</span>;
  }

  const steps = [
    {
      title: "Upplýsingar",
      content: (
        <UserUpdateForm
          isCheckout
          checkoutPrevious={() => router.push("/karfa")}
          checkoutNext={() => handleStepChange(currentStep + 1)}
          checkoutButtonText={"Afhendingarleiðir"}
          setFormValues={setCheckoutUserData}
        />
      ),
    },
    {
      title: "Afhendingarleiðir",
      content: (
        <CheckoutShippingMethods
          user={userData}
          checkoutPrevious={() => handleStepChange(currentStep - 1)}
          checkoutNext={async () => {
            const { checkoutId, orderId } = await handleShippingSubmit({
              additional_body: {
                prescriptions: localStorage.getItem("prescriptionCart"),
              },
              user: userData,
            });
            router.push(
              {
                pathname: router.asPath.split("?")[0],
                query: {
                  ...router.query,
                  step: currentStep + 1,
                  id: orderId,
                  checkoutId,
                },
              },
              undefined,
              { shallow: true }
            );
          }}
          checkoutButtonText={"Greiðslumáti"}
          selected={selectedShippingMethod}
          onClick={setSelectedShippingMethod}
        />
      ),
    },
    {
      title: "Greiðslumáti",
      content: (
        <CheckoutPaymentMethods
          checkoutButtonText={"Klára pöntun"}
          handleStepChange={handleStepChange}
          checkoutPrevious={() => handleStepChange(currentStep - 1)}
          onClick={setSelectedPaymentMethod}
          selected={selectedPaymentMethod}
        />
      ),
    },
  ];

  return (
    <div className={styles["checkout-content"]}>
      <CartNotices cart={cartData} />
      <div className={styles["steps"]}>
        <Steps current={currentStep - 1} responsive>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className={styles["content"]}>
          {steps[currentStep - 1].content}
        </div>
      </div>
      <div className={styles["order"]}>
        <CheckoutSummary
          cartData={cartData}
          shipping={selectedShippingMethod}
          prescriptionCartData={prescriptionCartData}
        />
      </div>
    </div>
  );
};

export { CheckoutContent };
