import React from "react";

import PDFSVG from "@assets/svg/pdf.svg";

import styles from "./productLink.module.scss";

interface ProductLinkProps {
  label: string;
  url: string;
}

const ProductLink = ({ label, url }: ProductLinkProps) => {
  return (
    <div className={styles["container"]}>
      <PDFSVG />
      <a href={url} className={styles["label"]}>
        {label}
      </a>
    </div>
  );
};

export { ProductLink };
