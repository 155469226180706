import React, { FC } from "react";

import { MedioWooOrder } from "@types";
import { formatDateShort, WooStatus } from "@utils";
import { StatusTag } from "@components/atoms";

import styles from "./orderDetailsGeneral.module.scss";

type OrderDetailsGeneralProps = {
  order: MedioWooOrder;
};

const OrderDetailsGeneral: FC<OrderDetailsGeneralProps> = ({ order }) => {
  if (!order) {
    return null;
  }

  return (
    <div className={`${styles["order-details-general"]} medio-content-box`}>
      <div className={`${styles["item"]} ${styles["status"]}`}>
        <div className={styles["item-key"]}>Staða pöntunar:</div>
        <div className={styles["item-value"]}>
          <StatusTag status={order.status as WooStatus} />
        </div>
      </div>
      <div className={`${styles["item"]}`}>
        <div className={styles["item-key"]}>Pöntunarnúmer:</div>
        <div className={styles["item-value"]}>{order.id}</div>
      </div>
      <div className={`${styles["item"]}`}>
        <div className={styles["item-key"]}>Dagsetning:</div>
        <div className={styles["item-value"]}>
          {formatDateShort(order.date_created.date)}
        </div>
      </div>
      <div className={`${styles["item"]}`}>
        <div className={styles["item-key"]}>Greiðslumáti:</div>
        <div className={styles["item-value"]}>
          {order.payment_method_title || "-"}
        </div>
      </div>
      <div className={`${styles["item"]}`}>
        <div className={styles["item-key"]}>Sendingarmáti:</div>
        <div className={styles["item-value"]}>{order.shipping_method}</div>
      </div>
    </div>
  );
};

export { OrderDetailsGeneral };
