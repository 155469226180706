import { captureException, captureMessage } from "@sentry/nextjs";

const dev = process.env.NODE_ENV === "development";

const sentryExceptionHandler = (e) => {
  if (dev) {
    console.error(e);
    return;
  }

  if (typeof e === "string") {
    captureMessage(e);
  } else {
    captureException(e);
  }
};

export { sentryExceptionHandler };
