import axios from "axios";
import { QueryClient } from "react-query";

import { handleMedioApiError } from "@utils";

import { clearPrescriptionCartData } from "./cart";

const logout = async (queryClient: QueryClient) => {
  try {
    clearPrescriptionCartData(queryClient);
    const { data, status } = await axios.post("/api/logout");
    if (status === 200) {
      localStorage.removeItem("prescriptionCart");
    }
    return data;
  } catch (e) {
    handleMedioApiError(e);
    return false;
  }
};

export { logout };
