import React, { FC } from "react";
import { Button, Form, Input } from "antd";
const { Item } = Form;
const { Group } = Input;
import styles from "./peiInput.module.scss";

interface PeiValidateFormProps {
  onFinish: (data: any) => void;
}

const PeiValidateForm: FC<PeiValidateFormProps> = ({ onFinish }) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      scrollToFirstError
      className={styles["pei-validate"]}
    >
      <Group className={styles["pei-input"]}>
        <Item
          label="Staðfestingarkóði"
          required
          name="pin"
          className={styles["pin"]}
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              len: 5,
            },
          ]}
        >
          <Input />
        </Item>
        <Item className={styles["button"]}>
          <Button
            type="primary"
            className="primary"
            size="large"
            htmlType="submit"
          >
            Staðfesta
          </Button>
        </Item>
      </Group>
    </Form>
  );
};

export { PeiValidateForm };
