enum MedioRole {
  technician = "Technician",
  pharmacist = "Pharmacist",
  manager = "Manager",
  root = "Root",
}

enum WooStatus {
  completed = "completed",
  processing = "processing",
  prescription = "prescription",
  pending = "pending",
  "on-hold" = "on-hold",
  shipping = "shipping",
  refunded = "refunded",
  cancelled = "cancelled",
  failed = "failed",
}

enum PrescriptionLabelType {
  controlled = "controlled",
  rSigned = "rSigned",
  waitingList = "waitingList",
  genericDrugs = "genericDrugs",
  prescriptionNotReady = "prescriptionNotReady",
}

enum RequestMethod {
  GET = "GET",
  PATCH = "PATCH",
  PUT = "PUT",
  POST = "POST",
  DELETE = "DELETE",
}

export { MedioRole, WooStatus, PrescriptionLabelType, RequestMethod };
