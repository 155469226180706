import axios from "axios";
import { useQuery } from "react-query";

import { MedioPrescriptionData } from "@types";
import { handleMedioApiError } from "@utils";

const getPrescriptionData = async (): Promise<MedioPrescriptionData> => {
  try {
    const { data, status } = await axios("/api/rxa/prescriptions");
    if (status === 401 || status === 403) {
      localStorage.removeItem("prescriptionCart");
    }
    return data;
  } catch (e) {
    // TODO: misisng code property
    handleMedioApiError(e);
    // console.log({ ...e });
    throw e.response.data;
  }
};

const usePrescriptionData = () => {
  return useQuery<MedioPrescriptionData, Error>({
    queryKey: ["prescriptionData"],
    queryFn: () => getPrescriptionData(),
    retry: false,
  });
};

export { usePrescriptionData, getPrescriptionData };
