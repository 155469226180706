import Lottie from "react-lottie-player";

import { MedioLoader } from "@components/molecules";

import styles from "./style.module.scss";

const OrderDetailsPending = () => {
  return (
    <div className={styles["container"]}>
      <MedioLoader
        loading
        noBackground
        title=""
        subtitle="Staldraðu við á meðan unnið er úr gögnum"
      />
    </div>
  );
};

export { OrderDetailsPending };
