import React from "react";
import styles from "./ordersSkeletons.module.scss";
import orderListStyles from "../../molecules/ordersTable/ordersTable.module.scss";
import { Skeleton } from "antd";
import { OrdersTableHeader } from "..";

const OrdersSkeletons = () => {
  return (
    <>
      <OrdersTableHeader />
      {Array(Math.floor(Math.random() * 5) + 2)
        .fill("")
        .map((_, index) => (
          <div
            className={`${orderListStyles["order"]} ${styles["order-list-skeleton"]}`}
            key={index}
          >
            <Skeleton title={false} paragraph={{ rows: 5 }} active />
            <Skeleton
              className={styles["mobile-only"]}
              title={false}
              paragraph={{ rows: 5 }}
              active
            />
          </div>
        ))}
    </>
  );
};

export { OrdersSkeletons };
