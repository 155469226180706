import { Button, Tooltip } from "antd";
import classNames from "classnames";
import React, { FC } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";

import CartSVG from "@assets/svg/cart.svg";
import { useUpdateCart } from "@kaktus-kreatives/ecommerce";
import { MedioAlgoliaWooProduct } from "@types";

import styles from "./itemCartButton.module.scss";

interface ItemCartButtonProps {
  product: MedioAlgoliaWooProduct;
  cartQuantity: number;
}

const ItemCartButton: FC<ItemCartButtonProps> = ({ product, cartQuantity }) => {
  const updateCart = useUpdateCart();

  if (product.stock <= 0) {
    return <span>Ekki til á lager</span>;
  }

  const PlusButton = () => {
    if (
      parseInt(product.maximum_allowed_quantity) !== NaN &&
      cartQuantity >= parseInt(product.maximum_allowed_quantity)
    ) {
      return (
        <Tooltip
          title={`Hámarksmagn í körfu er ${product.maximum_allowed_quantity}`}
          destroyTooltipOnHide
          mouseLeaveDelay={0}
        >
          <div className={classNames(styles["tooltip-content"])}>
            <Button
              disabled
              className="plus primary disabled"
              icon={<FiPlus size={24} stroke="#fff" />}
            />
          </div>
        </Tooltip>
      );
    }

    if (cartQuantity >= product.stock) {
      return (
        <Tooltip
          title={`Aðeins til ${product.stock} á lager`}
          destroyTooltipOnHide
          mouseLeaveDelay={0}
        >
          <div className={classNames(styles["tooltip-content"])}>
            <Button
              disabled
              icon={<FiPlus size={24} stroke="#fff" />}
              className="plus primary disabled"
            />
          </div>
        </Tooltip>
      );
    }

    return (
      <Button
        className={classNames(styles["plus"], "primary", {
          disabled: cartQuantity >= product.stock,
        })}
        disabled={cartQuantity >= product.stock}
        onClick={() => updateCart(product.id, 1)}
        icon={<FiPlus size={24} stroke="#fff" />}
      />
    );
  };

  const MinusButton = () => {
    return (
      <Button
        className={classNames(styles["minus"], styles["light"])}
        disabled={cartQuantity <= 0}
        onClick={() => updateCart(product.id, -1)}
        icon={<FiMinus size={24} stroke="#35b9bf" />}
      />
    );
  };

  // not in cart - display a single button
  if (cartQuantity === 0) {
    return (
      <div className={classNames(styles["item-cart-button"])}>
        <Button
          className={classNames(styles["single"], "primary")}
          onClick={() => updateCart(product.id, 1)}
          icon={<CartSVG />}
        >
          Setja í körfu
        </Button>
      </div>
    );
  }

  return (
    <div className={classNames(styles["item-cart-button"])}>
      <MinusButton />
      <div className={classNames(styles["quantity"])}>{cartQuantity}</div>
      <PlusButton />
    </div>
  );
};

export { ItemCartButton };
